<template>
    <v-container>
        <v-row justify="center" align="center">
            <v-col md="6" cols="12" justify="center" align="center" id="result">
                <h4 class="mt-16 print">Dosha Quiz Result</h4>
                <h1 class="title-result-dosha-2">Your dominant Dosha is</h1>
                <!-- <img src="../../assets/kapha.png" alt=""> -->
                <img :src="`/assets/${resultQuiz.result_1.namedosha}.png`"
                    v-bind:alt="resultQuiz.result_1.namedosha"><br>
                <v-btn large outlined color="#8CA182" onclick="print()" style="margin-top: 20px;" class="no-print">Save
                    Result as PDF</v-btn>

                <!-- <p style="text-align: justify;">{{ resultQuiz.result_1.longdesc }}</p> -->
            </v-col>
            <v-col md="6" cols="12" class="mt-16">
                <v-card style="background: #F8F3E8; padding: 24px;">
                    <v-card-text>
                        <div v-for="data in resultQuiz.percentages" :key="data.name">

                            <h2>{{ data.namedosha }}</h2>
                            <p>{{ data.desc }}</p>
                            <v-progress-linear color="primary" height="30" :value='data.percentage'>
                                <template v-slot:default="{ value }">
                                    <strong>{{ Math.ceil(value) }}%</strong>
                                </template></v-progress-linear>
                            <br>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col md="10" cols="12" justify="center">

                <p class="title-result-dosha-1 mb-4 mt-16">What does it mean for you?</p>
                <h3 class="mb-2">What is a Dosha?</h3>
                <p>
                    Ayurveda, the ‘science of life,’ is built on the concepts of the three Doshas (Tridosha). Doshas are
                    the
                    fundamental bio-energies formed by the combination of Pancha Mahabhutas (the five great
                    elements—earth,
                    water, fire, air, and space). These elements exist in the universe and also in the human body.<br>
                    Each
                    of them has a very specific set of roles to play in the body—when the Doshas are out of balance,
                    they
                    can cause disease and distress.
                </p>
                <h3 class="mb-2">You are predominantly {{ resultQuiz.result_1.namedosha }}</h3>
                <div v-html="resultQuiz.result_1.longdesc"></div>
                <h3 class="mb-2">Your secondary dosha is {{ resultQuiz.result_2.namedosha }}</h3>
                <div v-html="resultQuiz.result_2.longdesc"></div>
            </v-col>
            <v-col md="7" cols="12" justify="center" align="center">
                <img src="../../assets/icon-2.png" alt="" class="mt-8">
                <p class="text-quote mb-8">Understanding Doshas and knowing your body type helps to identify disease
                    before
                    it
                    takes hold. Ayurveda
                    provides a roadmap for an individual to live a healthy, perfectly balanced life using specific
                    herbs,
                    holistic cleanses/detox treatments and a personalized diet that is aligned with one’s unique dosha
                    constitution.</p>
            </v-col>
        </v-row>
        <v-row justify="center" align="center" class="">
            <h2 class="text-blog-result mt-16 mb-4">Bringing your Doshas into balance</h2>
            <p class="desc-blog-result mb-16 ">Explore these articles written by Oneworld’s Ayurvedic doctors to learn
                how
                to keep
                your predominant Dosha in
                balance. <br>From what to eat to how to exercise, these guidelines will help you to live a healthier and
                more
                grounded lifestyle.
            </p>

            <v-row justify="center" align="center" class="no-print">
                <v-col md="4" cols="12" justify="center" align="center">
                    <a href="https://oneworldayurveda.com/blog/digestion-tips-for-the-doshas/" target="_blank">
                        <img src="../../assets/blog/Blog-cover-dosha-digestion.jpg" alt="" width="100%">
                    </a>
                </v-col>
                <v-col md="4" cols="12" justify="center" align="center">
                    <a href="https://oneworldayurveda.com/blog/how-to-balance-your-doshas-for-healthy-and-radiant-skin/"
                        target="_blank">
                        <img src="../../assets/blog/Blog-cover-dosha-radiant-skin.jpg" alt="" width="100%">
                    </a>
                </v-col>
                <v-col md="4" cols="12" justify="center" align="center">
                    <a href="https://oneworldayurveda.com/blog/how-the-dosha-respond-to-stress/" target="_blank">
                        <img src="../../assets/blog/Blog-cover-dosha-stress.jpg" alt="" width="100%">
                    </a>
                </v-col>
                <v-col md="4" cols="12" justify="center" align="center"
                    class="d-none d-sm-none d-md-block d-lg-block d-xl-block">
                    <a href="https://oneworldayurveda.com/blog/what-are-the-ideal-exercises-for-each-dosha/"
                        target="_blank">
                        <img src="../../assets/blog/Blog-cover-dosha-exercise.jpg" alt="" width="100%">
                    </a>
                </v-col>
                <v-col md="4" cols="12" justify="center" align="center"
                    class="d-none d-sm-none d-md-block d-lg-block d-xl-block">
                    <a href="https://oneworldayurveda.com/blog/dosha-mind-types-vata-pitta-kapha-which-one-are-you/"
                        target="_blank">
                        <img src="../../assets/blog/Blog-cover-dosha-mind.jpg" alt="" width="100%">
                    </a>
                </v-col>
                <v-col md="4" cols="12" justify="center" align="center"
                    class="d-none d-sm-none d-md-block d-lg-block d-xl-block">
                    <a href="https://oneworldayurveda.com/blog/improve-your-sleep-quality-according-to-your-dosha-type/"
                        target="_blank">
                        <img src="../../assets/blog/Blog-cover-dosha-sleep.jpg" alt="" width="100%">
                    </a>
                </v-col>
            </v-row>
            <a href="https://oneworldayurveda.com/blog/tag/dosha/" target="_blank" class="mb-16">More tips and
                articles
                in our
                blog
                »</a>
        </v-row>
        <v-row justify="center" align="center" class="bg-tips no-print">
            <v-col md="8" cols="12" justify="center" align="center">
                <h2 class="text-tips-result mt-16 mb-2">Want to learn more about your unique Dosha combination or
                    address a
                    specific health issue?</h2>
            </v-col>
        </v-row>
        <v-row justify="center" align="center" class="bg-tips pb-4 no-print">
            <!-- row 1 -->
            <v-col md="3" cols="12" justify="center" align="center">
                <v-btn fab outlined color="#8CA182" class="btn-result-promo">1</v-btn>
            </v-col>
            <v-col md="4" cols="12" justify="center" align="center">
                <img src="../../assets/home-consultation.png" alt="" width="100%">
            </v-col>
            <v-col md="5" cols="12">
                <h3 class="ps-4">ONLINE CONSULTATION</h3>
                <p class="pe-4 ps-4"> In one-on-one online sessions, Oneworld Ayurveda’s Ayurvedic Doctors will look at
                    your
                    Doshas, their
                    imbalances while asking about your health conditions. The doctor will create a program just for
                    you—recommending specific medicines, dietary and exercise plans.</p>
                <v-btn large color="#8CA182" class="btn-result-primary ml-4"
                    href="https://oneworldayurveda.com/online-ayurvedic-consultation/">BOOK A CONSULTATION</v-btn>
            </v-col>
            <!-- row 2 -->
            <v-col md="3" cols="12" justify="center" align="center">
                <v-btn fab outlined color="#8CA182" class="btn-result-promo">2</v-btn>
            </v-col>
            <v-col md="4" cols="12" justify="center" align="center">
                <img src="../../assets/t-treatment-nasyam1.png" alt="" width="100%">
            </v-col>
            <v-col md="5" cols="12">
                <h3 class="ps-4">PANCHAKARMA DETOX IN BALI</h3>
                <p class="ps-4 pe-4"> A Panchakarma at Oneworld Ayurveda is a 7 to 28-night all-inclusive healing
                    journey
                    based on the science
                    of Ayurveda, with first-class service and quality. Choose between our to locations in Bali, in Ubud
                    and
                    Tegallalang.</p>
                <v-btn large color="#8CA182" class="btn-result-primary ml-4" href="https://oneworldayurveda.com/">MORE
                    ABOUT
                    PANCHAKARMA</v-btn>
            </v-col>
            <!-- row 3 -->
            <v-col md="12" cols="12" justify="center" align="center">
                <h3>OR</h3>
            </v-col>
            <v-col md="3" cols="12" justify="center" align="center">
            </v-col>
            <v-col md="4" cols="12" justify="center" align="center">
                <img src="../../assets/home-detox.png" alt="" width="100%">
            </v-col>
            <v-col md="5" cols="12">
                <h3 class="ps-4">7-DAY ONLINE DETOX PROGRAM</h3>
                <p class="ps-4 pe-4">Developed by distinguished Ayurvedic doctors, yoga teachers and chefs, this 7-day
                    program brings an
                    Ayurvedic detox into your home. You’ll be amazed at the changes you’ll see in just one week!</p>
                <v-btn large color="#8CA182" class="btn-result-primary ml-4"
                    href="https://oneworldayurveda.com/7-day-online-ayurvedic-detox-program/">DETOX & REBOOT NOW</v-btn>
            </v-col>
        </v-row>
        <v-row justify="center" align="center" class="no-print">

            <v-col md="8" cols="12" justify="center" align="center">
                <h2 class="text-tips-result mt-16 mb-2">Have more questions?</h2>
                <p>Our team are ready to answer any questions you may have about Ayurveda, send us a message and we'll
                    get
                    back to you</p>
            </v-col>
            <v-col md="8" cols="12" justify="center" align="center">
                <v-btn large outlined color="#8CA182" href="https://oneworldayurveda.com/form-contact/">DROP US A
                    LINE</v-btn>
            </v-col>
            <v-col md="8" cols="12" justify="center" align="center" class="mb-16">
                <v-btn class="ma-2" icon color="primary" href="https://www.instagram.com/oneworldayurveda/">
                    <v-icon>mdi-instagram</v-icon>
                </v-btn>
                <v-btn class="ma-2" icon color="primary" href="https://www.youtube.com/oneworldayurveda">
                    <v-icon>mdi-youtube</v-icon>
                </v-btn>
                <v-btn class="ma-2" icon color="primary" href="https://web.facebook.com/OneworldAyurveda/">
                    <v-icon>mdi-facebook</v-icon>
                </v-btn>
                <v-btn class="ma-2" icon color="primary"
                    href="https://www.google.com/maps/place//data=!4m2!3m1!1s0x2dd23d48c8a78579:0xd1720a952f483efb?source=g.page.m._">
                    <v-icon>mdi-google</v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<style>
.btn-result-promo {
    width: 120px !important;
    height: 120px !important;
    font-size: 28px !important;
}

.btn-result-primary {
    color: #ffffff !important;
    font-weight: 500 !important;
}

.text-tips-result {
    color: #000000;
    font-size: 30px;
    font-weight: 400;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: center;
}

.bg-tips {
    background-color: #F8F3E8;
}

.text-blog-result {
    font-size: 36px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
}

.desc-blog-result {
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: center;
}

.text-quote {
    font-family: Nunito Sans;
    font-size: 24px;
    font-style: italic;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: center;
    color: #000000;
}

.text-result-dosha-white {
    color: #ffffff !important;
}

.title-result-dosha {
    color: #202020 !important;
    font-weight: 300 !important;
    font-size: 56px !important;
}

.title-result-dosha-1 {
    color: #202020 !important;
    font-weight: 300 !important;
    font-size: 30px !important;
    text-align: center;
}

.title-result-dosha-2 {
    color: #202020 !important;
    font-weight: 300 !important;
    font-size: 44px !important;
    text-align: center;
}


.text-result-sub-1 {
    font-weight: 700 !important;
    font-size: 24px !important;
    line-height: 36px !important;
}

.text-p-1 {
    color: #000000 !important;
    font-size: 16px !important;
    font-weight: 400 !important;
}

@media print {

    .no-print,
    .no-print * {
        display: none !important;
    }

    header {
        display: none;
    }

    footer {
        display: none;
    }

}
</style>
<script>
import axios from 'axios';
import * as Printjs from "print-js";
export default {
    metaInfo: {
        title: 'Dosha Quiz',
        titleTemplate: '%s - Oneworld Ayurveda'
    },
    data: () => ({
        skill: 20,
        knowledge: 33,
        power: 78,
        resultQuiz: {}
    }),
    methods: {
        print() {
            Printjs({
                printable: "print-registration-form", //Id to print content 
                type: "HTML"
            });
        },
        fetchDoshaQuiz() {
            this.dialog = false
            axios.get(`https://api.oneworldbali.com/free-dosha-quiz/${this.$route.params.id}`).then(response => {
                // axios.get(`http://localhost:3344/free-dosha-quiz/${this.$route.params.id}`).then(response => {
                this.resultQuiz = response.data
            }).catch(error => {
                console.log(error);
            })
        }
    }, mounted() {
        this.fetchDoshaQuiz()
    },
}
</script>