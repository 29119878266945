<template>
  <v-app>
    <v-app-bar app color="white" elevate-on-scroll class="no-print disable-select">
      <div class="d-flex align-center">
        <a href="https://www.oneworldayurveda.com/">
          <v-img class="shrink mr-2" max-height="150" max-width="160" src="./assets/logo.png"></v-img>
        </a>
      </div>

      <v-spacer></v-spacer>

      <v-menu rounded="rounded" offset-y>
        <template v-slot:activator="{ attrs, on }">
          <v-btn text color="accent" class="hidden-sc" v-bind="attrs" v-on="on">
            tegallalang location
          </v-btn>
        </template>

        <v-list>
          <v-list-item v-for="experience in experiences" :key="experience" :href="experience.link"
            :class="[experience.name == 'RESERVATION' ? 'bgReser' : '']">
            <v-list-item-title class="submenu" v-text="experience.name"></v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu rounded="rounded" offset-y>
        <template v-slot:activator="{ attrs, on }">
          <v-btn text color="accent" class="hidden-sc" v-bind="attrs" v-on="on">
            ubud location
          </v-btn>
        </template>

        <v-list>
          <v-list-item v-for="program in programs" :key="program" :href="program.link"
            :class="[program.name == 'RESERVATION' ? 'bgReser' : '']">
            <v-list-item-title class="submenu" v-text="program.name"></v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu rounded="rounded" offset-y>
        <template v-slot:activator="{ attrs, on }">
          <v-btn text color="accent" class="hidden-sc" v-bind="attrs" v-on="on">
            ayurveda
          </v-btn>
        </template>

        <v-list>
          <v-list-item v-for="ayurveda in ayurvedas" :key="ayurveda" :href="ayurveda.link">
            <v-list-item-title class="submenu" v-text="ayurveda.name"></v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu rounded="rounded" offset-y>
        <template v-slot:activator="{ attrs, on }">
          <v-btn text color="accent" class="hidden-sc" v-bind="attrs" v-on="on">
            More Services
          </v-btn>
        </template>

        <v-list>
          <v-list-item v-for="certificate in certificates" :key="certificate" :href="certificate.link">
            <v-list-item-title class="submenu" v-text="certificate.name"></v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu rounded="rounded" offset-y>
        <template v-slot:activator="{ attrs, on }">
          <v-btn text color="accent" class="hidden-sc" v-bind="attrs" v-on="on">
            about
          </v-btn>
        </template>

        <v-list>
          <v-list-item v-for="about in abouts" :key="about" :href="about.link">
            <v-list-item-title class="submenu" v-text="about.name"></v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn href="https://www.oneworldayurveda.com/blog/" text color="accent" class="hidden-sc">
        <span>blog</span>
      </v-btn>
      <v-btn href="https://www.oneworldayurveda.com/form-contact/" text color="accent" class="hidden-sc">
        <span>contact</span>
      </v-btn>
      <v-app-bar-nav-icon class="hidden-mb" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" absolute temporary>
      <!-- <v-list>
        <div v-for="(link, i) in links" :key="i">
            <v-list-tile
                v-if="!link.subLinks"
                :to="link.to"
                :active-class="color"
                avatar
                class="v-list-item"
            >
              <v-list-tile-title v-text="link.text" />
            </v-list-tile>

            <v-list-group
                v-else
                :key="link.text"
            >
                <template v-slot:activator>
                  <v-list-tile>
                    <v-list-tile-content>
                      <v-list-tile-title>{{ link.text }}</v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                </template>

                <v-list-tile
                    v-for="sublink in link.subLinks"
                    :to="sublink.to"
                    :key="sublink.text"
                >
                    <v-list-tile-title v-text="sublink.text" />
                </v-list-tile>

            </v-list-group>

          </div>
      </v-list> -->
      <v-list nav dense>
        <v-list-item-group>
          <v-list-item v-for="link in links" :href="link.to" :key="link.text">
            <v-list-item-title class="submenu" v-text="link.text"></v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view></router-view>
    </v-main>

    <v-footer color="footer" class="no-print">
      <v-container>
        <v-row>
          <v-col cols="12" sm="4">
            <h2>Oneworld Ayurveda Tegallalang</h2>
            <p>Banjar Kebon <a href="https://www.oneworldayurveda.com/tegallalang/rooms-and-facilities/#map">(view
                map)</a> <br />
              Tegallalang, Bali - Indonesia <br />
              Resort tel.: +62 361 980970 <br />
              Tel./WhatsApp: <a href="https://wa.link/bn93d8">+62 8113 922 333</a> <br />
              Email: <a href="mailto:info@oneworldayurveda.com">info@oneworldayurveda.com</a>
            </p>
          </v-col>
          <v-col cols="12" sm="4">
            <h2>Oneworld Ayurveda Ubud</h2>
            <p>Jl. Suweta, Br. Sakti <a href="https://www.oneworldayurveda.com/ubud/rooms-and-facilities/#map">(view
                map)</a> <br />
              Ubud, Bali - Indonesia <br />
              Resort tel.: +62 361 972685 <br />
              Tel./WhatsApp: <a href="https://wa.link/f0ipkf">+62 8113 803 536</a> <br />
              Email: <a href="mailto:info@oneworldayurveda.com">info@oneworldayurveda.com</a></p>
          </v-col>
          <v-col cols="12" sm="4">
            <h2>Subscribe to Our Newsletter</h2>
            <p>and be first to hear about our lastest update and exclusive offers.</p>

            <div class="sib-form">
              <div id="sib-form-container" class="sib-form-container">
                <div id="error-message" class="sib-form-message-panel"
                  style="font-size:16px; text-align:left;  color:#661d1d; background-color:#ffeded; border-radius:3px;  ;max-width:350px;">
                  <div class="sib-form-message-panel__text sib-form-message-panel__text--center">
                    <svg viewBox="0 0 512 512" class="sib-icon sib-notification__icon">
                      <path
                        d="M256 40c118.621 0 216 96.075 216 216 0 119.291-96.61 216-216 216-119.244 0-216-96.562-216-216 0-119.203 96.602-216 216-216m0-32C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm-11.49 120h22.979c6.823 0 12.274 5.682 11.99 12.5l-7 168c-.268 6.428-5.556 11.5-11.99 11.5h-8.979c-6.433 0-11.722-5.073-11.99-11.5l-7-168c-.283-6.818 5.167-12.5 11.99-12.5zM256 340c-15.464 0-28 12.536-28 28s12.536 28 28 28 28-12.536 28-28-12.536-28-28-28z" />
                    </svg>
                    <span class="sib-form-message-panel__inner-text">
                      Your subscription could not be saved. Please try again.
                    </span>
                  </div>
                </div>
                <div></div>
                <div id="success-message" class="sib-form-message-panel"
                  style="font-size:16px; text-align:left;  color:#085229; background-color:#e7faf0; border-radius:3px; border-color:#13ce66;max-width:350px;">
                  <div class="sib-form-message-panel__text sib-form-message-panel__text--center">
                    <svg viewBox="0 0 512 512" class="sib-icon sib-notification__icon">
                      <path
                        d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 464c-118.664 0-216-96.055-216-216 0-118.663 96.055-216 216-216 118.664 0 216 96.055 216 216 0 118.663-96.055 216-216 216zm141.63-274.961L217.15 376.071c-4.705 4.667-12.303 4.637-16.97-.068l-85.878-86.572c-4.667-4.705-4.637-12.303.068-16.97l8.52-8.451c4.705-4.667 12.303-4.637 16.97.068l68.976 69.533 163.441-162.13c4.705-4.667 12.303-4.637 16.97.068l8.451 8.52c4.668 4.705 4.637 12.303-.068 16.97z" />
                    </svg>
                    <span class="sib-form-message-panel__inner-text">
                      Your subscription has been successful.
                    </span>
                  </div>
                </div>
                <div></div>
                <div id="sib-container" class="sib-container--medium sib-container--vertical"
                  style="text-align:center; background-color:rgba(106,119,105,1); max-width:350px; border-radius:3px; border-width:1px; direction:ltr">
                  <form id="sib-form" method="POST"
                    action="https://bd6c823b.sibforms.com/serve/MUIFANI94Y0GR9ENkIVnqm8Z1cS4znunvnQT9k-OsXE6WFKTgA0m9kBjTAVBz5Sj1R7zwz3WU_lnYkG3-uw_otf1aWPy7HRM_3ME-HPNdkzsW5hjDoqosoJCgnKFFtj7nu6nkZ6ibONowdVggp1H7Y8eD5O218O5KlQjYHb6vppXK1-TN72yxHDaiExoloQXDXOqKvomLO07uJjw"
                    data-type="subscription">
                    <div style="padding: 4px 0;">
                      <div class="sib-input sib-form-block">
                        <div class="form__entry entry_block">
                          <div class="form__label-row ">

                            <div class="entry__field">
                              <input class="input " type="text" id="EMAIL" name="EMAIL" autocomplete="off"
                                placeholder="EMAIL" data-required="true" required />
                            </div>
                          </div>

                          <label class="entry__error entry__error--primary"
                            style="font-size:16px; text-align:left;  color:#661d1d; background-color:#ffeded; border-radius:3px;  ;">
                          </label>
                        </div>
                      </div>
                    </div>
                    <div style="padding: 4px 0;">
                      <div class="sib-captcha sib-form-block">
                        <div class="form__entry entry_block">
                          <div class="form__label-row ">
                            <div class="g-recaptcha sib-visible-recaptcha" id="sib-captcha"
                              data-sitekey="6LdIMMAUAAAAAGcoWY6vCM7GFqh2wozrrpMEaogu"
                              data-callback="handleCaptchaResponse" style="direction:ltr"></div>
                          </div>
                          <label class="entry__error entry__error--primary"
                            style="font-size:16px; text-align:left;  color:#661d1d; background-color:#ffeded; border-radius:3px;  ;">
                          </label>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div class="sib-form-block" style="text-align: left">
                        <button class="sib-form-block__button sib-form-block__button-with-loader"
                          style="font-size:15px; text-align:left; font-weight:700;  color:#FFFFFF; background-color:#7a8b7b; border-radius:1px; border-width:0px;"
                          form="sib-form" type="submit">
                          <svg class="icon clickable__icon progress-indicator__icon sib-hide-loader-icon"
                            viewBox="0 0 512 512">
                            <path
                              d="M460.116 373.846l-20.823-12.022c-5.541-3.199-7.54-10.159-4.663-15.874 30.137-59.886 28.343-131.652-5.386-189.946-33.641-58.394-94.896-95.833-161.827-99.676C261.028 55.961 256 50.751 256 44.352V20.309c0-6.904 5.808-12.337 12.703-11.982 83.556 4.306 160.163 50.864 202.11 123.677 42.063 72.696 44.079 162.316 6.031 236.832-3.14 6.148-10.75 8.461-16.728 5.01z" />
                          </svg>
                          SUBSCRIBE
                        </button>
                      </div>
                    </div>

                    <input type="text" name="email_address_check" value="" class="input--hidden">
                    <input type="hidden" name="locale" value="en">
                  </form>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row style="padding:56px 0 24px;">
          <v-col cols="12" sm="4">
            <v-img class="shrink footer-mb mb-center" max-width="150"
              src="./assets/OWA-logo-2022-white-400px.png"></v-img>
          </v-col>
          <v-col cols="12" sm="4">
            <p class="mb-center"><a href="https://www.oneworldayurveda.com/privacy-policy/">Privacy policy</a> | © {{
          new
            Date().getFullYear() }}, Oneworld Ayurveda.</p>
          </v-col>
          <v-col style="text-align:end;" class="mb-center" cols="12" sm="4">
            <v-btn class="ma-2" text icon color="white" href="https://www.instagram.com/oneworldayurveda/">
              <v-icon>mdi-instagram</v-icon>
            </v-btn>
            <v-btn class="ma-2" text icon color="white" href="https://www.youtube.com/oneworldayurveda">
              <v-icon>mdi-youtube</v-icon>
            </v-btn>
            <v-btn class="ma-2" text icon color="white" href="https://web.facebook.com/OneworldAyurveda/">
              <v-icon>mdi-facebook</v-icon>
            </v-btn>
            <v-btn class="ma-2" text icon color="white"
              href="https://www.google.com/maps/place//data=!4m2!3m1!1s0x2dd23d48c8a78579:0xd1720a952f483efb?source=g.page.m._">
              <v-icon>mdi-google</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      drawer: false,
      group: null,
      experiences: [
        {
          'name': 'PANCHAKARMA INFO & PRICE',
          'link': 'https://www.oneworldayurveda.com/tegallalang/'
        },
        {
          'name': 'RESERVATION',
          'link': 'https://book.oneworldayurveda.com/tegallalang/panchakarma-form/'
        },
        {
          'name': 'SHORTER STAYS',
          'link': 'https://oneworldayurveda.com/tegallalang/shorter-stays/'
        },
        {
          'name': 'TESTIMONIAL',
          'link': 'https://www.oneworldayurveda.com/tegallalang/testimonials/'
        },
        {
          'name': 'ROOM , FACILITIES & ADD ONS',
          'link': 'https://www.oneworldayurveda.com/tegallalang/rooms-and-facilities/'
        },
        // {
        // 'name': 'TEAM',
        // 'link': 'https://www.oneworldayurveda.com/tegallalang/team/'
        // },
        {
          'name': 'PHOTO GALLERY',
          'link': 'https://www.oneworldayurveda.com/tegallalang/gallery/'
        },
        // {
        // 'name': 'GIFT CERTIFICATE',
        // 'link': 'https://www.oneworldayurveda.com/tegallalang/form-gift-certificate-onsite/'
        // }
      ],
      programs: [
        {
          'name': 'PANCHAKARMA INFO & PRICE',
          'link': 'https://www.oneworldayurveda.com/ubud/'
        },
        {
          'name': 'PANCHAKARMA CALENDAR',
          'link': 'https://oneworldayurveda.com/ubud/calendar/'
        },
        {
          'name': 'RESERVATION',
          'link': 'https://book.oneworldayurveda.com/ubud/panchakarma-form/'
        },
        {
          'name': 'SHORTER STAYS',
          'link': 'https://oneworldayurveda.com/ubud/shorter-stays/'
        },
        // {
        //   'name': 'AYURVEDIC EXPERIENCE (3 DAYS/2 NIGHTS)',
        //   'link': 'https://oneworldayurveda.com/ubud/shorter-stays/'
        // },
        // {
        //   'name': 'PANCHAKARMA WITH GANGASHREE & TINA',
        //   'link': 'https://oneworldayurveda.com/ubud/panchakarma-with-gangashree-nair-and-tina-stephens/'
        // },
        {
          'name': 'YOGA RETREATS',
          'link': 'https://www.oneworldayurveda.com/ubud/yoga-retreats/'
        },
        {
          'name': 'TESTIMONIAL',
          'link': 'https://www.oneworldayurveda.com/ubud/testimonials/'
        },
        {
          'name': 'ROOM , FACILITIES & ADD ONS',
          'link': 'https://www.oneworldayurveda.com/ubud/rooms-and-facilities/'
        },
        // {
        //   'name': 'TEAM',
        //   'link': 'https://www.oneworldayurveda.com/ubud/team/'
        // },
        {
          'name': 'PHOTO GALLERY',
          'link': 'https://www.oneworldayurveda.com/ubud/gallery/'
        },
        // {
        //   'name': 'GIFT CERTIFICATE',
        //   'link': 'https://www.oneworldayurveda.com/ubud/form-gift-certificate-onsite/'
        // }
      ],
      ayurvedas: [
        {
          'name': 'FACT ABOUT AYURVEDA',
          'link': 'https://www.oneworldayurveda.com/facts-about-ayurveda/'
        },
        {
          'name': 'WHAT HAPPEN IN A PANCHAKARMA',
          'link': 'https://www.oneworldayurveda.com/what-happens-in-a-panchakarma/'
        },
        {
          'name': 'AYURVEDIC TREATMENT',
          'link': 'https://www.oneworldayurveda.com/ayurvedic-treatments/'
        }
      ],
      certificates: [
        {
          'name': 'AYURVEDIC CONSULTATION',
          'link': 'https://oneworldayurveda.com/ayurvedic-consultation/'
        },
        {
          'name': '7-DAY ONLINE DETOX PROGRAM',
          'link': 'https://www.oneworldayurveda.com/7-day-online-ayurvedic-detox-program/'
        },
        {
          'name': 'AYURVEDIC RECIPE BOOK',
          'link': 'https://www.oneworldayurveda.com/ayurvedic-recipe-book/'
        },
        // {
        //   'name': 'SENDING BALI VIBES THROUGH YOGA',
        //   'link': 'https://www.oneworldayurveda.com/sending-bali-vibes/'
        // },
        // {
        //   'name': 'DISCOVER AYURBEDIC WITH DASHAMA',
        //   'link': 'https://www.oneworldayurveda.com/discover-ayurveda-with-dashama/'
        // },
        {
          'name': 'FREE DOSHA QUIZ',
          'link': 'https://book.oneworldayurveda.com/free-dosha-quiz/'
        },
        {
          'name': 'GIFT CERTIFICATE',
          'link': 'https://oneworldayurveda.com/form-gift-certificate/'
        }
      ],
      abouts: [
        {
          'name': 'WHO WE ARE',
          'link': 'https://www.oneworldayurveda.com/about-oneworld-ayurveda/'
        },
        {
          'name': 'THE TEAM',
          'link': 'https://www.oneworldayurveda.com/team/'
        },
        {
          'name': 'MEDIA CENTER',
          'link': 'https://www.oneworldayurveda.com/media-center/'
        },
        {
          'name': 'PRESS PUBLICATIONS',
          'link': 'https://www.oneworldayurveda.com/press-publications/'
        },
        {
          'name': 'PARTICIPANTS DISCLAIMER',
          'link': 'https://www.oneworldayurveda.com/program-participants-disclaimer/'
        },
        {
          'name': 'FAQS',
          'link': 'https://www.oneworldayurveda.com/faqs/'
        }
      ],
      links: [
        {
          to: 'https://www.oneworldayurveda.com/',
          text: 'Home',
        },
        {
          to: 'https://www.oneworldayurveda.com/panchakarma-at-oneworld-ayurveda/',
          text: 'On-site experiences',
        },
        {
          to: 'https://oneworldayurveda.com/more-services',
          text: 'More Services',
        },
        {
          to: 'https://www.oneworldayurveda.com/gift-certificate-onsite/',
          text: 'Gift certificate',
        },
        {
          to: 'https://www.oneworldayurveda.com/special-offers/',
          text: 'Special offer',
        },
        {
          to: 'https://www.oneworldayurveda.com/facts-about-ayurveda/',
          text: 'Ayurveda',
        },
        {
          to: 'https://blog.oneworldayurveda.com/',
          text: 'Blog',
        },
        {
          to: 'https://oneworldretreats.com/about-us/',
          text: 'About',
        },
        {
          to: 'https://www.oneworldayurveda.com/contact/',
          text: 'Contact',
        },
      ]
    }
  },
  watch: {
    group() {
      this.drawer = false
    },
  },
};
</script>

<style>
a {
  text-decoration: none;
  color: #959D4F !important;
}

.sticky-top {
  position: sticky;
  top: 60px;
}

iframe {
  height: 100%;
  width: 100%;
}

.bgReser {
  background-color: #d0dfd4 !important;
}

.hidden-mb {
  display: none !important;
}

.submenu {
  font-size: 0.8rem !important;
  color: #000 !important;
}

.text-hidden.v-text-field--enclosed .v-text-field__details {
  display: none !important;
}

@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@500&display=swap');

.v-picker {
  width: 100% !important;
}

header a,
header button {
  font-family: 'Work Sans', sans-serif !important;
  font-size: 13px !important;
}

.v-toolbar__content a {
  font-family: 'Work Sans', sans-serif !important;
}

.v-application {
  font-family: 'Nunito Sans', sans-serif !important;
  color: #555555 !important;
}

h1 {
  font-weight: 200;
  line-height: 1.18em !important;
  color: #617262 !important;
  font-size: 34px;
  margin-bottom: 25px;
}

.text-span {
  font-size: 12px;
  font-weight: 400;
  color: #777771;
}

h6 {
  font-weight: 500;
  font-family: 'Nunito Sans';
  font-size: 17px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  margin-top: 24px;
  color: #617262 !important;
  margin-bottom: 8px;
}

h2 {
  font-weight: 200;
  line-height: 1.18em !important;
  color: #617262 !important;
  font-size: 30px;
  /* margin-bottom: 25px; */
}

footer h2 {
  font-weight: 200;
  line-height: 1.18em !important;
  color: #ffff !important;
  font-size: 20px;
}

.h2-mb-10 {
  margin-bottom: 16px !important;
}

h4 {
  line-height: 1.18em !important;
  font-family: 'Nunito Sans' !important;
  letter-spacing: -.01em;
  font-size: 25px;
  line-height: 1.05714em;
  font-weight: 500;
  margin: 25px 0px;
  color: #677869 !important;
}

p {
  /* font-weight: 300; */
  color: #555555;
  font-size: 17px;
  line-height: 1.6;
  font-family: 'Open Sans' !important;
}

/* font end */

.v-text-field--outlined fieldset {
  border: 0 !important;
  background-color: #EDEDED !important;
  border-radius: 0 !important;
}

.v-btn {
  font-family: 'Work Sans', sans-serif !important;
}

@media only screen and (max-width: 600px) {
  .sticky-top {
    position: relative;
    top: 0;
  }

  iframe {
    height: 600px;
  }

  .hidden-mb {
    display: block !important;
  }

  .hidden-sc {
    display: none !important;
    ;
  }
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.border-information {
  border: #768c7a 1px solid;
  padding: 20px 20px 20px 25px;
}

li,
.para {
  /* font-weight: 300; */
  color: #555555;
  font-size: 17px;
  line-height: 1.6;
  font-family: 'Nunito Sans' !important;
}

.para {
  margin-top: 40px;
}

.border-information ul li {
  padding-left: 4px;
}

.v-footer {
  padding: 40px 0px !important;
}

footer p {
  margin-top: 14px;
  color: #ced3c5 !important;
  font-size: 14px;
  line-height: 1.7em;
}

footer a {
  color: #ffff !important;
  text-decoration: none;
  font-family: 'Nunito Sans' !important;
}

hr {
  border: #536354 1px solid !important;
}

.footer-mt {
  margin-top: 20px;
}

.footer-mb {
  margin-bottom: 0px;
}

@media only screen and (max-width: 600px) {
  .mb-center {
    margin-left: auto;
    margin-right: auto;
    text-align: center !important;
    ;
  }
}

#mc_embed_signup form {
  margin: 0 !important;
}

#mc_embed_signup .mc-field-group {
  width: 100% !important;
}

#mc_embed_signup .button {
  width: 100% !important;
  height: auto !important;
  padding: 4px !important;
  border-radius: 0px !important;
  background: linear-gradient(to right, #405861 50%, #768C7A 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all .2s ease-out;
}

#mce-EMAIL {
  background-color: #fff;
  border-radius: 0 !important;
}
</style>