<template>
  <v-data-table
    :headers="headers"
    :items="custs"
    hide-default-footer
    class="elevation-1"
  ></v-data-table>
</template>
<script>
  export default {
    name: 'GuestTable',
    props: {
        custs: Object
    },
    data () {
      return {
        headers: [
          {
            text: 'Full Name',
            align: 'start',
            sortable: false,
            value: 'firstname',
          },
          { text: 'Country of origin', value: 'country', sortable: false},
          { text: 'Mobile phone number', value: 'phone', sortable: false},
          { text: 'Email address', value: 'email', sortable: false},
          { text: 'Have you done a Panchakarma before?', value: 'exprience', sortable: false}
        ],
      }
    },
  }
</script>