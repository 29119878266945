<template>
    <v-container>
        <v-row
            no-gutters
            justify="center"
            align="center"
        >
            <v-col md="6" cols="12" class="text-center center">
                <h6>OOPS! PAGE NOT FOUND</h6>
                <h1>404</h1>
                <h6>WE ARE SORRY, BUT THE PAGE YOU REQUESTED WAS NOT FOUND</h6>
                <v-btn depressed color="primary" href="https://www.oneworldayurveda.com/" target="_blank" style="margin-top:20px;">
                    BACK TO HOME
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    metaInfo: {
      title: 'Not Found',
      titleTemplate: '%s - Oneworld Ayurveda'
    }
}
</script>

<style scoped>
.center {
    margin: 150px 0px;
}
h6 {
    font-size: 15px;
}
h1 {
    font-size: 200px;
    font-weight: bold;
    margin-bottom: 0px;
}
</style>