import BookingForm from './components/BookingForm.vue'
import PaymentResult from './components/PaymentResult.vue'
import PaymentResultElse from './components/PaymentResultElse.vue'
import DetailsForm from './components/DetailsForm.vue'
import UbudBookingForm from './components/Ubud/PKBookingForm.vue'
import NotFound from './components/NotFound.vue'
import DoshaQuiz from './components/DoshaQuiz/QuizForm.vue'
import FreeDoshaQuiz from './components/DoshaQuizFree/QuizForm.vue'
import Result from './components/DoshaQuiz/ResultQuiz.vue'
import FreeResult from './components/DoshaQuizFree/ResultQuiz.vue'
import FormOnline from './components/FormOnline/MainForm.vue'
import DetoxOnline from './components/DetoxReboot/MainForm.vue'
import ShortStay from './components/Short/MainForm.vue'
import ShortStayUbud from './components/ShortUbud/MainForm.vue'

export default [
    {
        path: '/tegallalang/panchakarma-form',
        component: BookingForm
    },
    {
        path: '/ubud/panchakarma-form',
        component: UbudBookingForm
    },
    {
        path: '/customer/:userid/deals/:dealsid', component: DetailsForm,
    },
    {
        path: '/confirmation/:userid1/:userid2', component: PaymentResult,
    },
    {
        path: '/confirmation-bank/:userid1/:userid2', component: PaymentResultElse,
    },
    {
        path: '/dosha-quiz', component: DoshaQuiz
    },
    {
        path: '/result-quiz/:id', component: Result
    },
    {
        path: '/free-dosha-quiz', component: FreeDoshaQuiz
    },
    {
        path: '/free-result-quiz/:id', component: FreeResult
    },
    {
        path: '/form-ayurvedic-consultation', component: FormOnline
    },
    {
        path: '/form-detox-reboot', component: DetoxOnline
    },
    {
        path: '/tegallalang/form-shorter-stay', component: ShortStay
    },
    {
        path: '/ubud/form-shorter-stay', component: ShortStayUbud
    },
    {
        path: '/404', component: NotFound
    },
    {
        path: '*', redirect: '/404'
    }
]