<template>
    <v-container>
        <v-row
            no-gutters
            justify="center"
        >
            <v-col md="8" cols="12">
                <h1 style="padding-top:120px;">Preparing for your Panchakarma</h1>
                <p>Soon you will begin your Panchakarma, how exciting!</p>
                <p>To make sure you get the best possible results, it is very helpful for us to get as much advance information as possible about your condition.</p>
                <p>Find below a few questions to be answered at your earliest convenience, your answers shall be reviewed by our Doctors in order to make the ideal preparation for you.</p>
                <p>Rest assure that all information will be treated confidentially.</p>
                <p style="padding-bottom: 64px;">Thank you very much for taking a few minutes to fill and send this form.</p>
            </v-col>
        </v-row>
        <v-row
            no-gutters
            justify="center"
        >
            <v-col md="8" cols="12">
                <v-form
                    v-model="valid"
                    ref="form"
                    lazy-validation
                >
                    <v-row>
                        <v-col cols="12" md="4">
                            Full Name
                            <p><b>{{guest.firstname}} {{guest.lastname}}</b></p>
                        </v-col>
                        <v-col cols="12" md="4">
                            Email
                            <p><b>{{guest.email}}</b></p>
                        </v-col>
                        <v-col cols="12" md="4">
                            Phone number
                            <p><b>{{guest.phone}}</b></p>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="6">
                            Date of Birth <span style="color:#ec0000;">*</span>
                            <v-menu v-model="menu" offset-y :close-on-content-click="false">
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-model="payload[0].Date_of_Birth"
                                        v-on="on"
                                        outlined
                                        :rules="textRules"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="picker" @click="menu = false" :max="nowDate"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" md="6">
                            Nationality
                            <v-text-field
                                v-model="payload[0].Nationality"
                                outlined
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="4">
                            Home address
                            <v-text-field
                                v-model="payload[0].Home_address"
                                outlined
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                            City
                            <v-text-field
                                v-model="payload[0].City"
                                outlined
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                            Country
                            <p><b>{{guest.country}}</b></p>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <p>To streamline your check-in, please provide your passport number; this will be kept confidential and used only for your stay.</p>
                            Passport number
                            <v-text-field
                                v-model="payload[0].Passport_number"
                                outlined
                            ></v-text-field>
                        </v-col> 
                    </v-row>
                    <h6 class="h2-mb-10">Personalising your Panchakarma</h6>
                    <v-row>
                        <v-col cols="12" md="4">
                            Your clothes size <span style="color:#ec0000;">*</span>
                            <v-select
                                v-model="payload[0].Your_clothes_size"
                                :items="sizes"
                                outlined
                                :rules="textRules"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" md="4">
                            Shoe measurement <span style="color:#ec0000;">*</span>
                            <v-select
                                v-model="payload[0].Shoe_size_measurement"
                                :items="shoes"
                                outlined
                                :rules="textRules"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" md="4">
                            Your shoes size <span style="color:#ec0000;">*</span>
                            <v-text-field
                                v-model="payload[0].Your_shoe_size"
                                outlined
                                :rules="textRules"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <hr style="border:#ccc 1px solid; margin-bottom:20px;">
                    <h6 class="h2-mb-10">Your Health Condition</h6>
                    <v-row>
                        <v-col cols="12" md="6">
                            Are you experiencing any health issues at the moment? Please describe and tell us how long this has been going on and how it has been treated so far.
                            <v-textarea
                                v-model="payload[1].Presenting_complaints_C_O"
                                outlined
                            ></v-textarea>
                        </v-col>
                        <v-col cols="12" md="6">
                            Can you tell us about your past illnesses or surgeries? How old were you at that time, how long were you suffering from the condition, how was it treated?
                            <v-textarea
                                v-model="payload[1].Pre_PK_illnesses_surgeries"
                                outlined
                            ></v-textarea>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="6">
                            Are you currently under any medications? If so, please list them below.
                            <v-textarea
                                v-model="payload[1].Medications"
                                outlined
                            ></v-textarea>
                        </v-col>
                        <v-col cols="12" md="6">
                            Are you taking any supplements? If so please list them below.
                            <v-textarea
                                v-model="payload[1].Supplements"
                                outlined
                            ></v-textarea>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="6">
                            Have you undergone any blood, urine, stool examinations or had X-ray, Ultrasound, MRI etc. in the past 5 years? If yes, can you give us the reasons for them?
                            <v-textarea
                                v-model="payload[1].Examination_reports"
                                outlined
                            ></v-textarea>
                        </v-col>
                        <v-col cols="12" md="6">
                            Are you allergic to any substances - food, pollen, dust, medicines or other? If so, please specify.
                            <v-textarea
                                v-model="payload[1].Allergies"
                                outlined
                            ></v-textarea>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            Additional information. Please feel free to share any additional information you think might be relevant.
                            <v-textarea
                                v-model="payload[1].Additional_health_info"
                                outlined
                            ></v-textarea>
                        </v-col>
                    </v-row>
                    <hr style="border:#ccc 1px solid; margin-bottom:20px;">
                    <h6 class="h2-mb-10">Contact person in case of emergency</h6>
                    <v-row>
                        <v-col cols="12" md="6">
                            Name
                            <v-text-field
                                v-model="payload[0].Contact_person_name"
                                outlined
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            Mobile phone
                            <v-text-field
                                v-model="payload[0].Contact_person_phone_number"
                                outlined
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row style="margin-bottom:120px;">
                        <v-col>
                            <v-btn 
                                block
                                depressed
                                large
                                color="primary"
                                :loading="loading"
                                :disabled="!valid"
                                @click="sendDetails(payload)"
                            >
                                Send
                            </v-btn>
                            <v-snackbar
                                v-model="snackbar"
                            >
                                {{ text }}

                                <template v-slot:action="{ attrs }">
                                    <v-btn
                                        color="primary"
                                        text
                                        v-bind="attrs"
                                        @click="snackbar = false"
                                    >
                                    Close
                                    </v-btn>
                                </template>
                            </v-snackbar>
                        </v-col>
                    </v-row>
                </v-form>
            </v-col>
            <!-- <DetailsCustomer :valid="valid" :payload="payload" :form="$refs.form" /> -->
        </v-row>
    </v-container>
</template>

<script>
const axios = require('axios').default;
// import DetailsCustomer from '../components/Details/DetailsCustomer.vue';
export default {
    name: 'DetailsForm',
    data() {
        return {
            snackbar: false,
            text: '',
            hears: [
                'Google',
                'Instagram',
                'Facebook',
                'Youtube',
                'Friend',
                'Email/newsletter'
            ],
            guest: [],
            sizes: [
                'XS',
                'S',
                'M',
                'L',
                'XL',
                'XXL'
            ],
            shoes: [
                'EU',
                'US',
                'UK/AU'
            ],
            expe: [
                {
                    'id': 'Yes',
                    'value': true
                },
                {
                    'id': 'No',
                    'value': false
                }
            ],
            menu: false,
            valid: true,
            loading: false,
            nowDate: new Date().toISOString().slice(0,10),
            textRules: [
                v => !!v || 'This field is required',
            ],
            payload: [
                {
                    id: this.$route.params.userid,
                    Date_of_Birth: '',
                    Nationality:'',
                    Home_address: '',
                    City: '',
                    Contact_person_name: '',
                    Contact_person_phone_number: '',
                    Your_clothes_size: '',
                    Your_shoe_size: '',
                    Passport_number: ''
                },
                {   
                    id: this.$route.params.dealsid,
                    Presenting_complaints_C_O: '',
                    Pre_PK_illnesses_surgeries: '',
                    Medications: '',
                    Supplements: '',
                    Additional_health_info: '',
                    Examination_reports: '',
                    Allergies: ''
                }
            ]
        }
    },
    methods: {
        async sendDetails(payload) {
            try {
                this.$refs.form.validate()   
            } catch (error) {
                this.text = 'Please fullfil the details'
                this.snackbar = true
            }
            if (this.$refs.form.validate() != false) {
                this.loading = true
                await axios.post('https://api.oneworldbali.com/save-details', payload).then(response => {
                // await axios.post('http://localhost:3344/save-details', payload).then(response => {
                if (response.status == 200) {
                    this.loading= false 
                    window.location.href = 'https://www.oneworldayurveda.com/thankyou-followup/';
                } else {
                    this.loading= false 
                    this.text = 'Please contact our reservation team for this issue.'
                    this.snackbar = true
                }
                }).catch(() => {
                    this.loading= false 
                    this.text = 'Please contact our reservation team for this issue.'
                    this.snackbar = true
                });   
            } else {
                this.text = 'Oops! Please complete all required fields before you proceed.'
                this.snackbar = true
            }
        }
    },
    computed: {
        picker: {
            get() {
                return this.value;
            },
            set(val) {
                this.menu = false;
                this.payload[0].Date_of_Birth = val
            }
        }
    },
    mounted() {
        axios.post('https://api.oneworldbali.com/users', {
            'userid': this.$route.params.userid
        }).then(response => {
            this.guest = response.data[0]
        }).catch(error => {
            console.log(error);
        })
    },
    metaInfo: {
      title: 'Preparing for your Panchakarma',
      titleTemplate: '%s - Oneworld Ayurveda'
    }
}
</script>