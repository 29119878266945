import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
    payload: []
  },
  mutations: {
    setPayload (state, payload) {
      state.payload = payload
    }
  },
  actions: {
    setPayload (context, payload) {
      context.commit('setPayload', payload)
    }
  }
})

