<template>
    <v-container style="margin-bottom:120px;">
        <v-form v-model="valid" ref="form" lazy-validation>
            <v-row no-gutters justify="center">
                <v-col md="8" cols="12">
                    <v-row>
                        <v-col cols="12">
                            <h1 style="text-align:center;">Ayurvedic Consultation</h1>
                            I want to book<span style="color:#ec0000;">*</span>
                            <v-radio-group v-model="bookType" mandatory>
                                <v-radio v-for="(book, index) in books" :value="book.label" :label="book.label"
                                    :key="index + 'box'">
                                </v-radio>
                            </v-radio-group>

                            Consultation type<span style="color:#ec0000;">*</span>
                            <v-radio-group v-model="payload.consultationType" mandatory>
                                <v-radio v-for="(consultation, index) in consultations" :value="consultation.value"
                                    :label="consultation.label" :key="index + 'box'">
                                </v-radio>
                            </v-radio-group>

                            <div v-if="payload.consultationType === true">
                                Location
                                <v-select :items="locations" v-model="payload.location" outlined
                                    :rules="textRules"></v-select>
                            </div>

                            <div cols="12"
                                v-show="payload.location == 'Bali Botanica' && payload.consultationType == true">
                                <b>Please note: </b> On-site consultations at Bali Botanica are only available on
                                Tuesdays and Fridays.
                            </div>

                            <h6>CONTACT INFORMATION</h6>
                            <v-row>
                                <v-col cols="12" sm="4">
                                    Title <span style="color:#ec0000;">*</span>
                                    <v-select :items="titles" v-model="payload.title" outlined
                                        :rules="textRules"></v-select>
                                </v-col>
                                <v-col cols="12" sm="4">
                                    First Name <span style="color:#ec0000;">*</span>
                                    <v-text-field v-model="payload.firstName" outlined
                                        :rules="nameRules"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4">
                                    Last Name <span style="color:#ec0000;">*</span>
                                    <v-text-field v-model="payload.lastName" outlined :rules="nameRules"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <span><i>Important: If you're booking for a friend, please make sure you provide
                                            your friend's email address, not your own, as it will be associated with
                                            their Consultation information.</i></span>
                                </v-col>
                                <v-col cols="12" sm="4">
                                    City <span style="color:#ec0000;">*</span>
                                    <v-text-field v-model="payload.city" outlined :rules="nameRules"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4">
                                    Country of residence <span style="color:#ec0000;">*</span>
                                    <v-select :items="country" v-model="payload.country" item-text="name" outlined
                                        :rules="textRules"></v-select>
                                </v-col>
                                <v-col cols="12" sm="4">
                                    Email address <span style="color:#ec0000;">*</span>
                                    <v-text-field v-model="payload.email" outlined :rules="emailRules"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="4">
                                    Mobile phone <span style="color:#ec0000;">*</span>
                                    <v-text-field v-model="payload.phone" outlined :rules="numberRules"
                                        placeholder="62..."></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4">
                                    Whatsapp number
                                    <v-text-field v-model="payload.wa" outlined :disabled="payload.samewa"
                                        :rules="whatsAppRules" placeholder="62..."></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4">
                                    <v-checkbox v-model="setWa" label="I use the same number for WhatsApp"></v-checkbox>
                                </v-col>
                            </v-row>
                            <h6>APPOINTMENT SCHEDULE</h6>
                            <v-row>
                                <v-col cols="12" md="6">
                                    Preferred Date <span style="color:#ec0000;">*</span>
                                    <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40"
                                        transition="scale-transition" offset-y min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field v-model="payload.arrivalDate" outlined readonly v-bind="attrs"
                                                v-on="on" placeholder="YYYY-MM-DD"></v-text-field>
                                        </template>
                                        <v-date-picker :min="date" v-model="picker"
                                            @input="menu = false"></v-date-picker>
                                    </v-menu>
                                </v-col>

                                <v-col cols="12" sm="6">
                                    Preferred time<span style="color:#ec0000;">*</span>
                                    <v-select :items="cons_time" v-model="payload.arrivalTime" outlined
                                        :rules="textRules"></v-select>
                                </v-col>
                                <p style="font-size: 15px;">For the Special Package, the schedule for remaining
                                    consultations will be arranged between
                                    you and your doctor.</p>
                            </v-row>

                            <h6>HEALTH INFORMATION</h6>
                            <v-row>
                                <v-col cols="12" sm="4">
                                    Date of Birth <span style="color:#ec0000;">*</span>
                                    <v-menu v-model="menu2" offset-y :close-on-content-click="false">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field v-model="payload.Date_of_Birth" v-on="on" outlined
                                                :rules="textRules" placeholder="YYYY-MM-DD"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="pickerbirthday" @click="menu2 = false"
                                            :max="nowDate"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="12" sm="4">
                                    Weight
                                    <v-text-field v-model="payload.weight" outlined></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4">
                                    Height
                                    <v-text-field v-model="payload.height" outlined></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    Have you had any Ayurvedic consultation at Oneworld Ayurveda before?
                                    <v-radio-group row v-model="payload.consultationBefore">
                                        <v-radio v-for="(method, index) in consultationBefore" :key="index"
                                            :label="method.id" :value="method.id" :rules="textRules"></v-radio>
                                    </v-radio-group>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    Are you suffering from any health issues? If yes, what are they?
                                    <v-textarea v-model="payload.healthIssue" outlined></v-textarea>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    Are you taking any medications? If yes, whats are they?
                                    <v-textarea v-model="payload.underMedication" outlined></v-textarea>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            Promo code/Voucher code (optional - valid discounts will be applied to your balance payment)
                            <v-text-field outlined v-model="payload.voucherCode"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn block depressed large color="primary" :loading="loading" @click="submit(payload)">
                                Submit
                            </v-btn>

                            <v-snackbar v-model="snackbar">
                                {{ text }}

                                <template v-slot:action="{ attrs }">
                                    <v-btn color="primary" text v-bind="attrs" @click="snackbar = false">
                                        Close
                                    </v-btn>
                                </template>
                            </v-snackbar>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>

<script>
import axios from 'axios'
export default {
    name: 'BookingForm',
    data() {
        return {
            payload: {
                typeConsultation: 'Single consultation',
                consultationType: false,
                location: '',
                price: 1260000,
                title: '',
                firstName: '',
                lastName: '',
                city: '',
                email: '',
                phone: '',
                wa: '',
                consultationBefore: '',
                samewa: false,
                hour: '',
                time: '',
                healthIssue: '',
                underMedication: '',
                arrivalDate: '',
                voucherCode: '',
                country: '',
                weight: '',
                height: '',
                arrivalTime: '',
                Date_of_Birth: ''
            },
            loading: false,
            snackbar: false,
            valid: true,
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            menu: false,
            menu2: false,
            books: [
                {
                    label: "1 Ayurvedic Consultation (Rp 1,575,000)",
                    price: "1575000",
                    name: "Single consultation"
                },
                {
                    label: "Special Package 5 Consultations for the Price of 4 (Rp 6,300,000)",
                    price: "6300000",
                    name: "Package of 5 consultation"
                },
            ],
            consultations: [
                {
                    label: 'Online',
                    value: false

                },
                {
                    label: 'Onsite',
                    value: true
                }
            ],
            titles: [
                "Mr.",
                "Mrs.",
                "Ms."
            ],
            locations: [
                "Oneworld Ayurveda Ubud",
                "Oneworld Ayurveda Tegallalang",
                "Bali Botanica"
            ],
            cons_time: [
                "08:00 AM",
                "09:00 AM",
                "10:00 AM",
                "11:00 AM",
                "12:00 PM",
                "01:00 PM",
                "02:00 PM",
                "03:00 PM",
                "04:00 PM",
                "05:00 PM",
                "06:00 PM",
                "07:00 PM",
                "08:00 PM",
            ],
            consultationBefore: [
                {
                    'id': 'Yes',
                },
                {
                    'id': 'No',
                }
            ],
            textRules: [
                v => !!v || 'This field is required',
            ],
            nameRules: [
                v => !!v || 'This field is required',
                v => /^[a-zA-Z -]+$/.test(v) || 'Name must use alphabet',
            ],
            numberRules: [
                v => !!v || 'This field is required',
                v => /^\d+$/.test(v) || 'Only numbers are allowed',
            ],
            whatsAppRules: [
                v => /^\d+$/.test(v) || 'Only numbers are allowed',
            ],
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            ],
            country: [
                { name: 'Afghanistan', code: 'AF' },
                { name: 'Åland Islands', code: 'AX' },
                { name: 'Albania', code: 'AL' },
                { name: 'Algeria', code: 'DZ' },
                { name: 'American Samoa', code: 'AS' },
                { name: 'AndorrA', code: 'AD' },
                { name: 'Angola', code: 'AO' },
                { name: 'Anguilla', code: 'AI' },
                { name: 'Antarctica', code: 'AQ' },
                { name: 'Antigua and Barbuda', code: 'AG' },
                { name: 'Argentina', code: 'AR' },
                { name: 'Armenia', code: 'AM' },
                { name: 'Aruba', code: 'AW' },
                { name: 'Australia', code: 'AU' },
                { name: 'Austria', code: 'AT' },
                { name: 'Azerbaijan', code: 'AZ' },
                { name: 'Bahamas', code: 'BS' },
                { name: 'Bahrain', code: 'BH' },
                { name: 'Bangladesh', code: 'BD' },
                { name: 'Barbados', code: 'BB' },
                { name: 'Belarus', code: 'BY' },
                { name: 'Belgium', code: 'BE' },
                { name: 'Belize', code: 'BZ' },
                { name: 'Benin', code: 'BJ' },
                { name: 'Bermuda', code: 'BM' },
                { name: 'Bhutan', code: 'BT' },
                { name: 'Bolivia', code: 'BO' },
                { name: 'Bosnia and Herzegovina', code: 'BA' },
                { name: 'Botswana', code: 'BW' },
                { name: 'Bouvet Island', code: 'BV' },
                { name: 'Brazil', code: 'BR' },
                { name: 'British Indian Ocean Territory', code: 'IO' },
                { name: 'Brunei Darussalam', code: 'BN' },
                { name: 'Bulgaria', code: 'BG' },
                { name: 'Burkina Faso', code: 'BF' },
                { name: 'Burundi', code: 'BI' },
                { name: 'Cambodia', code: 'KH' },
                { name: 'Cameroon', code: 'CM' },
                { name: 'Canada', code: 'CA' },
                { name: 'Cape Verde', code: 'CV' },
                { name: 'Cayman Islands', code: 'KY' },
                { name: 'Central African Republic', code: 'CF' },
                { name: 'Chad', code: 'TD' },
                { name: 'Chile', code: 'CL' },
                { name: 'China', code: 'CN' },
                { name: 'Christmas Island', code: 'CX' },
                { name: 'Cocos (Keeling) Islands', code: 'CC' },
                { name: 'Colombia', code: 'CO' },
                { name: 'Comoros', code: 'KM' },
                { name: 'Congo', code: 'CG' },
                { name: 'Congo, The Democratic Republic of the', code: 'CD' },
                { name: 'Cook Islands', code: 'CK' },
                { name: 'Costa Rica', code: 'CR' },
                { name: 'Cote D\'Ivoire', code: 'CI' },
                { name: 'Croatia', code: 'HR' },
                { name: 'Cuba', code: 'CU' },
                { name: 'Cyprus', code: 'CY' },
                { name: 'Czech Republic', code: 'CZ' },
                { name: 'Denmark', code: 'DK' },
                { name: 'Djibouti', code: 'DJ' },
                { name: 'Dominica', code: 'DM' },
                { name: 'Dominican Republic', code: 'DO' },
                { name: 'Ecuador', code: 'EC' },
                { name: 'Egypt', code: 'EG' },
                { name: 'El Salvador', code: 'SV' },
                { name: 'Equatorial Guinea', code: 'GQ' },
                { name: 'Eritrea', code: 'ER' },
                { name: 'Estonia', code: 'EE' },
                { name: 'Ethiopia', code: 'ET' },
                { name: 'Falkland Islands (Malvinas)', code: 'FK' },
                { name: 'Faroe Islands', code: 'FO' },
                { name: 'Fiji', code: 'FJ' },
                { name: 'Finland', code: 'FI' },
                { name: 'France', code: 'FR' },
                { name: 'French Guiana', code: 'GF' },
                { name: 'French Polynesia', code: 'PF' },
                { name: 'French Southern Territories', code: 'TF' },
                { name: 'Gabon', code: 'GA' },
                { name: 'Gambia', code: 'GM' },
                { name: 'Georgia', code: 'GE' },
                { name: 'Germany', code: 'DE' },
                { name: 'Ghana', code: 'GH' },
                { name: 'Gibraltar', code: 'GI' },
                { name: 'Greece', code: 'GR' },
                { name: 'Greenland', code: 'GL' },
                { name: 'Grenada', code: 'GD' },
                { name: 'Guadeloupe', code: 'GP' },
                { name: 'Guam', code: 'GU' },
                { name: 'Guatemala', code: 'GT' },
                { name: 'Guernsey', code: 'GG' },
                { name: 'Guinea', code: 'GN' },
                { name: 'Guinea-Bissau', code: 'GW' },
                { name: 'Guyana', code: 'GY' },
                { name: 'Haiti', code: 'HT' },
                { name: 'Heard Island and Mcdonald Islands', code: 'HM' },
                { name: 'Holy See (Vatican City State)', code: 'VA' },
                { name: 'Honduras', code: 'HN' },
                { name: 'Hong Kong', code: 'HK' },
                { name: 'Hungary', code: 'HU' },
                { name: 'Iceland', code: 'IS' },
                { name: 'India', code: 'IN' },
                { name: 'Indonesia', code: 'ID' },
                { name: 'Iran, Islamic Republic Of', code: 'IR' },
                { name: 'Iraq', code: 'IQ' },
                { name: 'Ireland', code: 'IE' },
                { name: 'Isle of Man', code: 'IM' },
                { name: 'Israel', code: 'IL' },
                { name: 'Italy', code: 'IT' },
                { name: 'Jamaica', code: 'JM' },
                { name: 'Japan', code: 'JP' },
                { name: 'Jersey', code: 'JE' },
                { name: 'Jordan', code: 'JO' },
                { name: 'Kazakhstan', code: 'KZ' },
                { name: 'Kenya', code: 'KE' },
                { name: 'Kiribati', code: 'KI' },
                { name: 'Korea, Democratic People\'S Republic of', code: 'KP' },
                { name: 'Korea, Republic of', code: 'KR' },
                { name: 'Kuwait', code: 'KW' },
                { name: 'Kyrgyzstan', code: 'KG' },
                { name: 'Lao People\'S Democratic Republic', code: 'LA' },
                { name: 'Latvia', code: 'LV' },
                { name: 'Lebanon', code: 'LB' },
                { name: 'Lesotho', code: 'LS' },
                { name: 'Liberia', code: 'LR' },
                { name: 'Libyan Arab Jamahiriya', code: 'LY' },
                { name: 'Liechtenstein', code: 'LI' },
                { name: 'Lithuania', code: 'LT' },
                { name: 'Luxembourg', code: 'LU' },
                { name: 'Macao', code: 'MO' },
                { name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK' },
                { name: 'Madagascar', code: 'MG' },
                { name: 'Malawi', code: 'MW' },
                { name: 'Malaysia', code: 'MY' },
                { name: 'Maldives', code: 'MV' },
                { name: 'Mali', code: 'ML' },
                { name: 'Malta', code: 'MT' },
                { name: 'Marshall Islands', code: 'MH' },
                { name: 'Martinique', code: 'MQ' },
                { name: 'Mauritania', code: 'MR' },
                { name: 'Mauritius', code: 'MU' },
                { name: 'Mayotte', code: 'YT' },
                { name: 'Mexico', code: 'MX' },
                { name: 'Micronesia, Federated States of', code: 'FM' },
                { name: 'Moldova, Republic of', code: 'MD' },
                { name: 'Monaco', code: 'MC' },
                { name: 'Mongolia', code: 'MN' },
                { name: 'Montserrat', code: 'MS' },
                { name: 'Morocco', code: 'MA' },
                { name: 'Mozambique', code: 'MZ' },
                { name: 'Myanmar', code: 'MM' },
                { name: 'Namibia', code: 'NA' },
                { name: 'Nauru', code: 'NR' },
                { name: 'Nepal', code: 'NP' },
                { name: 'Netherlands', code: 'NL' },
                { name: 'Netherlands Antilles', code: 'AN' },
                { name: 'New Caledonia', code: 'NC' },
                { name: 'New Zealand', code: 'NZ' },
                { name: 'Nicaragua', code: 'NI' },
                { name: 'Niger', code: 'NE' },
                { name: 'Nigeria', code: 'NG' },
                { name: 'Niue', code: 'NU' },
                { name: 'Norfolk Island', code: 'NF' },
                { name: 'Northern Mariana Islands', code: 'MP' },
                { name: 'Norway', code: 'NO' },
                { name: 'Oman', code: 'OM' },
                { name: 'Pakistan', code: 'PK' },
                { name: 'Palau', code: 'PW' },
                { name: 'Palestinian Territory, Occupied', code: 'PS' },
                { name: 'Panama', code: 'PA' },
                { name: 'Papua New Guinea', code: 'PG' },
                { name: 'Paraguay', code: 'PY' },
                { name: 'Peru', code: 'PE' },
                { name: 'Philippines', code: 'PH' },
                { name: 'Pitcairn', code: 'PN' },
                { name: 'Poland', code: 'PL' },
                { name: 'Portugal', code: 'PT' },
                { name: 'Puerto Rico', code: 'PR' },
                { name: 'Qatar', code: 'QA' },
                { name: 'Reunion', code: 'RE' },
                { name: 'Romania', code: 'RO' },
                { name: 'Russian Federation', code: 'RU' },
                { name: 'RWANDA', code: 'RW' },
                { name: 'Saint Helena', code: 'SH' },
                { name: 'Saint Kitts and Nevis', code: 'KN' },
                { name: 'Saint Lucia', code: 'LC' },
                { name: 'Saint Pierre and Miquelon', code: 'PM' },
                { name: 'Saint Vincent and the Grenadines', code: 'VC' },
                { name: 'Samoa', code: 'WS' },
                { name: 'San Marino', code: 'SM' },
                { name: 'Sao Tome and Principe', code: 'ST' },
                { name: 'Saudi Arabia', code: 'SA' },
                { name: 'Senegal', code: 'SN' },
                { name: 'Serbia and Montenegro', code: 'CS' },
                { name: 'Seychelles', code: 'SC' },
                { name: 'Sierra Leone', code: 'SL' },
                { name: 'Singapore', code: 'SG' },
                { name: 'Slovakia', code: 'SK' },
                { name: 'Slovenia', code: 'SI' },
                { name: 'Solomon Islands', code: 'SB' },
                { name: 'Somalia', code: 'SO' },
                { name: 'South Africa', code: 'ZA' },
                { name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
                { name: 'Spain', code: 'ES' },
                { name: 'Sri Lanka', code: 'LK' },
                { name: 'Sudan', code: 'SD' },
                { name: 'Suriname', code: 'SR' },
                { name: 'Svalbard and Jan Mayen', code: 'SJ' },
                { name: 'Swaziland', code: 'SZ' },
                { name: 'Sweden', code: 'SE' },
                { name: 'Switzerland', code: 'CH' },
                { name: 'Syrian Arab Republic', code: 'SY' },
                { name: 'Taiwan, Province of China', code: 'TW' },
                { name: 'Tajikistan', code: 'TJ' },
                { name: 'Tanzania, United Republic of', code: 'TZ' },
                { name: 'Thailand', code: 'TH' },
                { name: 'Timor-Leste', code: 'TL' },
                { name: 'Togo', code: 'TG' },
                { name: 'Tokelau', code: 'TK' },
                { name: 'Tonga', code: 'TO' },
                { name: 'Trinidad and Tobago', code: 'TT' },
                { name: 'Tunisia', code: 'TN' },
                { name: 'Turkey', code: 'TR' },
                { name: 'Turkmenistan', code: 'TM' },
                { name: 'Turks and Caicos Islands', code: 'TC' },
                { name: 'Tuvalu', code: 'TV' },
                { name: 'Uganda', code: 'UG' },
                { name: 'Ukraine', code: 'UA' },
                { name: 'United Arab Emirates', code: 'AE' },
                { name: 'United Kingdom', code: 'GB' },
                { name: 'United States', code: 'US' },
                { name: 'United States Minor Outlying Islands', code: 'UM' },
                { name: 'Uruguay', code: 'UY' },
                { name: 'Uzbekistan', code: 'UZ' },
                { name: 'Vanuatu', code: 'VU' },
                { name: 'Venezuela', code: 'VE' },
                { name: 'Viet Nam', code: 'VN' },
                { name: 'Virgin Islands, British', code: 'VG' },
                { name: 'Virgin Islands, U.S.', code: 'VI' },
                { name: 'Wallis and Futuna', code: 'WF' },
                { name: 'Western Sahara', code: 'EH' },
                { name: 'Yemen', code: 'YE' },
                { name: 'Zambia', code: 'ZM' },
                { name: 'Zimbabwe', code: 'ZW' }
            ],
        }
    },
    computed: {
        bookType: {
            get() {
                return this.value
            },
            set(val) {
                var data = this.books.find(r => r.label == val)
                this.payload.typeConsultation = data.name
                this.payload.price = data.price
            }
        },
        picker: {
            get() {
                return this.value;
            },
            set(val) {
                this.menu = false;
                this.payload.arrivalDate = val
            }
        },
        pickerbirthday: {
            get() {
                return this.value;
            },
            set(val) {
                this.menu2 = false;
                this.payload.Date_of_Birth = val
            }
        },
        setWa: {
            get() {
                return this.value
            },
            set(val) {
                if (val == true) {
                    this.payload.wa = this.payload.phone
                }
                this.payload.samewa = val
            }
        },
    },
    methods: {
        async submit(payload) {
            try {
                this.$refs.form.validate()
            } catch (error) {
                this.text = 'Please complete all required fields before submitting the form'
                this.snackbar = true
            }
            // if (this.form.validate() != false && this.payload.price > 0) { 
            if (this.$refs.form.validate() == true) {
                this.loading = true
                await axios.post('https://api.oneworldbali.com/online-consultation', payload).then(response => {
                    // await axios.post('http://localhost:3344/online-consultation', payload).then(response => {
                    if (response.status == 200) {
                        window.location.replace(response.data.link)
                    } else {
                        this.text = 'There is a something wrong, please contact the administrator'
                        this.snackbar = true
                    }
                }).catch(() => {
                    this.text = 'There is a something wrong, please contact the administrator'
                    this.snackbar = true
                });
                this.loading = false
            } else {
                this.text = 'Please complete all required fields before submitting the form'
                this.snackbar = true
            }
        }
    },
    metaInfo: {
        title: 'Online Ayurvedic Consultation Form',
        titleTemplate: '%s - Oneworld Ayurveda'
    }
}
</script>