<template>
    <v-container>
        <v-row>
            <v-col class="text-center">
                <h1>Thank you</h1>
                <br>
                <div>
                    <p>You will be receiving a confirmation within 48 hours.</p>
                    <p>If you do not hear from us, please email us at <a href="mailto:reservation@oneworldayurveda.com">reservation@oneworldayurveda.com</a></p>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <h3>Guests</h3>
                <GuestTable :custs="guests" />
            </v-col>
        </v-row>
        <v-row>
            <v-col class="text-center">
                <div>
                    <p>Gratefull,</p>
                    <p>The Team at Oneworld Ayurveda</p>
                    <p><a href="www.oneworldayurveda.com">www.oneworldayurveda.com</a></p>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
const axios = require('axios').default;
import GuestTable from './GuestTable.vue'
export default {
    name: 'PaymentResult',
    data() {
        return {
            guests: []
        }
    },
    mounted() {
        axios.post('https://api.oneworldbali.com/resultPayment', {
            zoho_id1: this.$route.params.userid1,
            zoho_id2: this.$route.params.userid2
        }).then(response => {
            // console.log(response);
            this.guests = response.data
        }).catch(error => {
            console.log(error);
        })
    },
    components: {
        GuestTable
    },
    metaInfo: {
        title: 'Confirmation Result',
        titleTemplate: '%s - Oneworld Ayurveda'
    }
}
</script>

<style scoped>
h1 {
    font-size: 4rem;    
}

p {
    margin: 0!important;
}

.text-center{
    text-align: center !important;
}
</style>
