<template>
    <v-container fluid>
        <v-row justify="center" align="center" class="bg-dosha banner-dosha">
            <v-col md="5" cols="12">
                <img src="../../assets/dosha_mobile.png" alt="" style="width: 80%; "
                    class="d-sm-block d-md-none d-lg-none d-xl-none mt-16">
                <h6 class="text-dosha-white">Dosha Quiz</h6>
                <h1 class="title-dosha text-white">Do you know what your Dosha type is ?</h1>
                <p class="text-dosha-white">Discover your unique Dosha type and learn how to live a healthier and more
                    balanced
                    life according to
                    ancient Ayurvedic wisdom.</p>
                <v-btn height="54" min-width="164" color="primary" href="#quiz" class="mb-8"
                    style="color: #ffffff !important;">Take the Quiz</v-btn>
            </v-col>
            <v-col md="4" cols="12" class="d-none d-sm-none d-md-block d-lg-block d-xl-block">
                <img src="../../assets/dosha.png" alt="" style="width: 100%;">
            </v-col>
        </v-row>

        <v-row justify="space-around" align="center" class="pt-12 pb-12" style="background-color: #F8F3E8;">
            <v-col md="4" cols="12">
                <h3 class="text-sub-1">Knowing your Dosha type is a valuable first step in your Ayurvedic journey to
                    wellness.</h3>
            </v-col>
            <v-col md="4" cols="12">
                <p class="text-p-1" style="font-size: 16px;">
                    According to Ayurveda, each person is born with a unique, unchanging combination of bioenergies or
                    Doshas. These three bio-energies are called Vata, Pitta, and Kapha and they govern the function of
                    our
                    bodies on a physical and emotional level and determine the body-mind constitution.<br> We all have
                    one
                    predominant Dosha, yet everyone has a little of the other two. When the Doshas are in balance, you
                    experience good health while imbalances can lead to disease.<br>Knowing your predominant Dosha helps
                    you
                    to tailor your lifestyle for health, vitality and longevity.
                </p>
            </v-col>
        </v-row>

        <v-row justify="center" align="center" id="quiz">
            <v-col md="6" cols="12" class="text-center mt-16">
                <h3>DISCOVER YOUR DOSHA NOW</h3>
                <p>When answering the questions, think about your whole life, or at least the last 12 months or more.
                    Select
                    the answers that most closely reflect your lived experience throughout your life.</p>
            </v-col>
        </v-row>

        <v-row justify="center" align="center">
            <v-form v-model="valid" ref="form" lazy-validation class="mb-4">
                <v-row justify="center" align="center">

                    <v-col lg="10" v-for="item in quizes" :key="item.name" cols="12">
                        <h3 class="mb-4 font-weight-bold">{{ item.name }}</h3>
                        <v-col v-for="question in item.questions" :key="question.no">
                            <h3 class="font-weight-bold">{{ question.no }}. {{ question.name }}</h3>
                            <v-radio-group v-model="question.answer">
                                <v-row>
                                    <v-col cols="12" lg="4" v-for="option in question.options" :key="option.value">
                                        <v-radio :rules="quizrules" :label="option.text"
                                            :value="option.value"></v-radio>
                                    </v-col>
                                </v-row>
                            </v-radio-group>
                        </v-col>
                    </v-col>
                    <v-col lg="10" cols="12">
                        <v-btn height="54" min-width="164" color="primary" @click.stop="dialog = true"
                            :disabled="!isFormValid" class="mb-16">Submit</v-btn>
                    </v-col>
                </v-row>
            </v-form>
            <!-- modal untuk lihat hasil -->
            <v-dialog v-model="dialog" max-width="460">
                <v-card>
                    <v-card-title class="text-h5">
                        Please fill in your information below to view the result
                    </v-card-title>

                    <v-col>
                        <v-card-text>
                            <v-row>
                                <v-col lg="6" cols="12">
                                    <v-text-field label="First Name" v-model="input.first_name"></v-text-field>
                                </v-col>
                                <v-col lg="6" cols="12">
                                    <v-text-field label="Last Name" v-model="input.last_name"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-text-field label="Email Address" :rules="rules" v-model="input.email"></v-text-field>
                            <v-select label="Gender" :items="gender" v-model="input.gender" item-text="name"
                                required></v-select>
                            <v-select label="Country" :items="country" v-model="input.country"
                                item-text="name"></v-select>
                            <v-select label="How familiar are you with Ayurveda?" :items="familiar"
                                v-model="input.familiar" item-text="name" required></v-select>
                            <div style="padding-bottom: 18px;">
                                <input type="checkbox" id="checkbox2" v-model="input.been_guest">
                                <label for="checkbox2"> I have been a guest of Oneworld.</label>
                            </div>
                            <label>By submitting this form, you consent to receiving future
                                updates on special promotions
                                and interesting Ayurvedic tips via email. We respect your privacy and won't share your
                                information</label>
                        </v-card-text>
                    </v-col>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="accent" @click="dialog = false" outlined tile>
                            Back
                        </v-btn>

                        <v-btn color="accent" @click="onSubmit()" :disabled="isLoading" :loading="isLoading" tile>
                            Submit
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-dialog v-model="isLoading" persistent width="400" lazy>
            <v-card color="primary" dark>
                <v-card-text>
                    Please stand by
                    <v-progress-linear interminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<style>
.bg-dosha {
    background: url(../../assets/bg-dosha.png);
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
}

.text-dosha-white {
    color: #ffffff !important;
}

.title-dosha {
    color: #ffffff !important;
    font-weight: 300 !important;
    font-size: 46px !important;
}

.banner-dosha {
    height: 100vh;
}

.text-sub-1 {
    font-weight: 700 !important;
    font-size: 24px !important;
    line-height: 36px !important;
}

.text-p-1 {
    color: #000000 !important;
    font-size: 16px !important;
    font-weight: 400 !important;
}
</style>

<script>

import axios from 'axios';

export default {
    metaInfo: {
        title: 'Dosha Quiz',
        titleTemplate: '%s - Oneworld Ayurveda'
    },
    data() {
        return {
            isLoading: false,
            valid: true,
            dialog: false,
            input: {
                salutation: 'Dosha',
                email: '',
                first_name: '',
                last_name: '',
                gender: '',
                country: '',
                mobile: '',
                familiar: '',
                been_guest: false,
                update_news: true,
                Experienced_PK_before: 'Yes',
            },
            quizrules: [
                value => !!value || 'Required.',
            ],
            rules: [
                value => !!value || 'Required.',
                value => (value || '').length <= 30 || 'Max 30 characters',
                value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                },
            ],
            quizes: [
                {
                    name: 'Physical Features',
                    questions: [
                        {
                            no: '1',
                            name: 'Weight',
                            answer: '',
                            options: [
                                {
                                    value: 'a',
                                    text: 'I am slim. It is difficult for me to gain weight.'
                                },
                                {
                                    value: 'b',
                                    text: 'I am of medium build and gain or lose weight easily depending on my diet and exercise.'
                                },
                                {
                                    value: 'c',
                                    text: 'I am of heavy build; I gain weight easily but have difficulties to lose it. '
                                },
                            ]
                        },
                        {
                            no: '2',
                            name: 'Eyes',
                            answer: '',
                            options: [
                                {
                                    value: 'a',
                                    text: 'I have dry eyes, sometimes they tend to flit.'
                                },
                                {
                                    value: 'b',
                                    text: 'My eyes easily get red or irritated, they are sensitive to light.'
                                },
                                {
                                    value: 'c',
                                    text: 'I have big, round eyes, my eyes are mostly clear.'
                                },
                            ]
                        },
                        {
                            no: '3',
                            name: 'Teeth',
                            answer: '',
                            options: [
                                {
                                    value: 'a',
                                    text: 'My teeth are irregularly arranged.'
                                },
                                {
                                    value: 'b',
                                    text: 'My teeth are well-arranged; I tend to have cavities.'
                                },
                                {
                                    value: 'c',
                                    text: 'I have well-arranged, strong, and rather big teeth.'
                                },
                            ]
                        },
                        {
                            no: '4',
                            name: 'Face',
                            answer: '',
                            options: [
                                {
                                    value: 'a',
                                    text: 'I have a long face.'
                                },
                                {
                                    value: 'b',
                                    text: 'My face is heart-shaped.'
                                },
                                {
                                    value: 'c',
                                    text: 'My face is round.'
                                },
                            ]
                        },
                        {
                            no: '5',
                            name: 'Complexion',
                            answer: '',
                            options: [
                                {
                                    value: 'a',
                                    text: 'My skin is dry, thin, and prone to wrinkles, it tans easily.'
                                },
                                {
                                    value: 'b',
                                    text: 'I have reddish and sensitive skin that sunburns easily, in some cases there might be pimples, acne scars, or moles.'
                                },
                                {
                                    value: 'c',
                                    text: 'My skin looks youthful, well moisturised, and smooth; I tend to tan versus to burn. The skin is clear and without marks or pimples.'
                                },
                            ]
                        },
                        {
                            no: '6',
                            name: 'Hair',
                            answer: '',
                            options: [
                                {
                                    value: 'a',
                                    text: 'My hair is dry and brittle; sometimes it falls out easily.'
                                },
                                {
                                    value: 'b',
                                    text: 'I have straight, thin hair, a tendency to premature graying.'
                                },
                                {
                                    value: 'c',
                                    text: 'My hair is abundant, thick, curly.'
                                },
                            ]
                        },
                        {
                            no: '7',
                            name: 'Nails',
                            answer: '',
                            options: [
                                {
                                    value: 'a',
                                    text: 'My nails are dry, they break easily.'
                                },
                                {
                                    value: 'b',
                                    text: 'My nails are smooth, pinkish, and flexible.'
                                },
                                {
                                    value: 'c',
                                    text: 'I have strong, thick, and smooth nails.'
                                },
                            ]
                        },
                    ],
                }, {
                    name: 'Physiological Attributes',
                    questions: [
                        {
                            no: '8',
                            name: 'Sleep Pattern',
                            answer: '',
                            options: [
                                {
                                    value: 'a',
                                    text: 'I have difficulty falling asleep and wake up often in the night; my sleep is light and gets disturbed easily.'
                                },
                                {
                                    value: 'b',
                                    text: 'I am a moderately sound sleeper and wake up energetic in the morning.'
                                },
                                {
                                    value: 'c',
                                    text: 'I need a minimum of 8 hours of sleep to feel rested, my sleep is deep and I awake slowly in the morning. '
                                },
                            ]
                        },
                        {
                            no: '9',
                            name: 'Body Temperature',
                            answer: '',
                            options: [
                                {
                                    value: 'a',
                                    text: 'My hands and feet are usually cold, and I prefer a warm environment.'
                                },
                                {
                                    value: 'b',
                                    text: 'I feel warm, regardless of the season, and prefer a cooler environment.'
                                },
                                {
                                    value: 'c',
                                    text: 'My body is cold and is adaptable to most temperatures, I prefer moderate temperatures.'
                                },
                            ]
                        },
                        {
                            no: '10',
                            name: 'Sweat',
                            answer: '',
                            options: [
                                {
                                    value: 'a',
                                    text: 'I barely sweat.'
                                },
                                {
                                    value: 'b',
                                    text: 'I sweat profusely even with minimum activity. '
                                },
                                {
                                    value: 'c',
                                    text: 'I sweat very little when doing a moderate activity. '
                                },
                            ]
                        },
                        {
                            no: '11',
                            name: 'Appetite ',
                            answer: '',
                            options: [
                                {
                                    value: 'a',
                                    text: 'I have a variable appetite or irregular meal times, I feel full easily even with small meals.'
                                },
                                {
                                    value: 'b',
                                    text: 'I have a strong appetite and feel hungry regularly and frequently.'
                                },
                                {
                                    value: 'c',
                                    text: 'I have a modest appetite and can skip meals easily.'
                                },
                            ]
                        },
                        {
                            no: '12',
                            name: 'Digestion',
                            answer: '',
                            options: [
                                {
                                    value: 'a',
                                    text: 'My digestion is irregular, I have a tendency to flatulence, bloating or gurgling sounds from my stomach after a moderate meal.'
                                },
                                {
                                    value: 'b',
                                    text: 'My digestion is quick and easy even after moderate meals; I tend to have acidity or heartburn.'
                                },
                                {
                                    value: 'c',
                                    text: 'My digestion is slow, I feel sleepy, lazy, and lethargic after moderately heavy meals.'
                                },
                            ]
                        },
                        {
                            no: '13',
                            name: 'What quality of food do you prefer?',
                            answer: '',
                            options: [
                                {
                                    value: 'a',
                                    text: 'Salty / savoury / crispy'
                                },
                                {
                                    value: 'b',
                                    text: 'Sweet / spicy / hot'
                                },
                                {
                                    value: 'c',
                                    text: 'Sweet / oily / creamy'
                                },
                            ]
                        },
                        {
                            no: '14',
                            name: 'Bowel Movement',
                            answer: '',
                            options: [
                                {
                                    value: 'a',
                                    text: 'My bowel movements are irregular, hard and I am often constipated.'
                                },
                                {
                                    value: 'b',
                                    text: 'My bowel movements are regular, more than once a day.'
                                },
                                {
                                    value: 'c',
                                    text: 'My bowel movements happen once in most days and are sluggish.'
                                },
                            ]
                        }
                    ]
                }, {

                    name: 'Emotional & Psychological Condition',
                    questions: [
                        {
                            no: '15',
                            name: 'My Reaction to Stress',
                            answer: '',
                            options: [
                                {
                                    value: 'a',
                                    text: 'I get anxious, confused, worried.'
                                },
                                {
                                    value: 'b',
                                    text: 'I am easily irritable or aggressive.'
                                },
                                {
                                    value: 'c',
                                    text: 'I remain calm and like to withdraw.'
                                },
                            ]
                        },
                        {
                            no: '16',
                            name: 'Movements and Reactions ',
                            answer: '',
                            options: [
                                {
                                    value: 'a',
                                    text: 'I am quick and spontaneous.'
                                },
                                {
                                    value: 'b',
                                    text: 'I am deliberate and organized.'
                                },
                                {
                                    value: 'c',
                                    text: 'I move and react slowly. '
                                },
                            ]
                        }, {
                            no: '17',
                            name: 'Speech',
                            answer: '',
                            options: [
                                {
                                    value: 'a',
                                    text: 'Fast and unclear'
                                },
                                {
                                    value: 'b',
                                    text: 'Precise and clear'
                                },
                                {
                                    value: 'c',
                                    text: 'Slow, long, and monotonous'
                                },
                            ]
                        },
                        {
                            no: '18',
                            name: 'Memory',
                            answer: '',
                            options: [
                                {
                                    value: 'a',
                                    text: 'I have a good short-term memory but easily forget.'
                                },
                                {
                                    value: 'b',
                                    text: 'I have a good medium-term memory.'
                                },
                                {
                                    value: 'c',
                                    text: 'I have good long-term memory; it takes a bit longer for me to grasp things but I never forget.'
                                },
                            ]
                        },
                        {
                            no: '19',
                            name: 'My Plan for the Next Five Years ',
                            answer: '',
                            options: [
                                {
                                    value: 'a',
                                    text: 'I have multiple plans. I do like changes but I am also sometimes worried about my future.'
                                },
                                {
                                    value: 'b',
                                    text: 'I want to grow in my career, to have more responsibilities and appreciation. I have clear plans to achieve security and resources.'
                                },
                                {
                                    value: 'c',
                                    text: 'I dislike too many changes in my life. I need more time to rest, relax, and to be by myself.'
                                },
                            ]
                        },
                        {
                            no: '20',
                            name: 'How do I manage my tasks ',
                            answer: '',
                            options: [
                                {
                                    value: 'a',
                                    text: 'I am very active and involved in various projects, I multitask and experiment but often do not complete my projects.'
                                },
                                {
                                    value: 'b',
                                    text: 'I rise to the challenge, I am dynamic, focused, and demonstrate leadership. I prefer to plan my actions, and I strive for perfection.'
                                },
                                {
                                    value: 'c',
                                    text: 'I am slow, steady, and dependable. I cannot multitask but always complete the tasks assigned to me.'
                                },
                            ]
                        },
                        {
                            no: '21',
                            name: 'Concentration',
                            answer: '',
                            options: [
                                {
                                    value: 'a',
                                    text: 'Easily to get distracted'
                                },
                                {
                                    value: 'b',
                                    text: 'Moderate, depends upon how interesting the topic is'
                                },
                                {
                                    value: 'c',
                                    text: 'Hard to get distracted'
                                },
                            ]
                        },
                        {
                            no: '22',
                            name: 'Emotions ',
                            answer: '',
                            options: [
                                {
                                    value: 'a',
                                    text: 'I am anxious, fearful, uncertain, too many choices confuse me.'
                                },
                                {
                                    value: 'b',
                                    text: 'I am ambitious and like to make plans; I can get irritated when interrupted.'
                                },
                                {
                                    value: 'c',
                                    text: 'I am calm, I like solitude and I am peace-loving.'
                                },
                            ]
                        }
                    ],
                }
            ],
            familiar: [
                { name: "I" + "'" + "m not familiar with Ayurveda" },
                { name: 'I know a bit' },
                { name: 'I am into Ayurveda' },
                { name: 'I am an expert/practitioner' },
            ],
            country: [
                { name: 'Afghanistan', code: 'AF' },
                { name: 'Aland Islands', code: 'AX' },
                { name: 'Albania', code: 'AL' },
                { name: 'Algeria', code: 'DZ' },
                { name: 'American Samoa', code: 'AS' },
                { name: 'AndorrA', code: 'AD' },
                { name: 'Angola', code: 'AO' },
                { name: 'Anguilla', code: 'AI' },
                { name: 'Antarctica', code: 'AQ' },
                { name: 'Antigua and Barbuda', code: 'AG' },
                { name: 'Argentina', code: 'AR' },
                { name: 'Armenia', code: 'AM' },
                { name: 'Aruba', code: 'AW' },
                { name: 'Australia', code: 'AU' },
                { name: 'Austria', code: 'AT' },
                { name: 'Azerbaijan', code: 'AZ' },
                { name: 'Bahamas', code: 'BS' },
                { name: 'Bahrain', code: 'BH' },
                { name: 'Bangladesh', code: 'BD' },
                { name: 'Barbados', code: 'BB' },
                { name: 'Belarus', code: 'BY' },
                { name: 'Belgium', code: 'BE' },
                { name: 'Belize', code: 'BZ' },
                { name: 'Benin', code: 'BJ' },
                { name: 'Bermuda', code: 'BM' },
                { name: 'Bhutan', code: 'BT' },
                { name: 'Bolivia', code: 'BO' },
                { name: 'Bosnia and Herzegovina', code: 'BA' },
                { name: 'Botswana', code: 'BW' },
                { name: 'Bouvet Island', code: 'BV' },
                { name: 'Brazil', code: 'BR' },
                { name: 'British Indian Ocean Territory', code: 'IO' },
                { name: 'Brunei Darussalam', code: 'BN' },
                { name: 'Bulgaria', code: 'BG' },
                { name: 'Burkina Faso', code: 'BF' },
                { name: 'Burundi', code: 'BI' },
                { name: 'Cambodia', code: 'KH' },
                { name: 'Cameroon', code: 'CM' },
                { name: 'Canada', code: 'CA' },
                { name: 'Cape Verde', code: 'CV' },
                { name: 'Cayman Islands', code: 'KY' },
                { name: 'Central African Republic', code: 'CF' },
                { name: 'Chad', code: 'TD' },
                { name: 'Chile', code: 'CL' },
                { name: 'China', code: 'CN' },
                { name: 'Christmas Island', code: 'CX' },
                { name: 'Cocos (Keeling) Islands', code: 'CC' },
                { name: 'Colombia', code: 'CO' },
                { name: 'Comoros', code: 'KM' },
                { name: 'Congo', code: 'CG' },
                { name: 'Congo, The Democratic Republic of the', code: 'CD' },
                { name: 'Cook Islands', code: 'CK' },
                { name: 'Costa Rica', code: 'CR' },
                { name: 'Cote D\'Ivoire', code: 'CI' },
                { name: 'Croatia', code: 'HR' },
                { name: 'Cuba', code: 'CU' },
                { name: 'Cyprus', code: 'CY' },
                { name: 'Czech Republic', code: 'CZ' },
                { name: 'Denmark', code: 'DK' },
                { name: 'Djibouti', code: 'DJ' },
                { name: 'Dominica', code: 'DM' },
                { name: 'Dominican Republic', code: 'DO' },
                { name: 'Ecuador', code: 'EC' },
                { name: 'Egypt', code: 'EG' },
                { name: 'El Salvador', code: 'SV' },
                { name: 'Equatorial Guinea', code: 'GQ' },
                { name: 'Eritrea', code: 'ER' },
                { name: 'Estonia', code: 'EE' },
                { name: 'Ethiopia', code: 'ET' },
                { name: 'Falkland Islands (Malvinas)', code: 'FK' },
                { name: 'Faroe Islands', code: 'FO' },
                { name: 'Fiji', code: 'FJ' },
                { name: 'Finland', code: 'FI' },
                { name: 'France', code: 'FR' },
                { name: 'French Guiana', code: 'GF' },
                { name: 'French Polynesia', code: 'PF' },
                { name: 'French Southern Territories', code: 'TF' },
                { name: 'Gabon', code: 'GA' },
                { name: 'Gambia', code: 'GM' },
                { name: 'Georgia', code: 'GE' },
                { name: 'Germany', code: 'DE' },
                { name: 'Ghana', code: 'GH' },
                { name: 'Gibraltar', code: 'GI' },
                { name: 'Greece', code: 'GR' },
                { name: 'Greenland', code: 'GL' },
                { name: 'Grenada', code: 'GD' },
                { name: 'Guadeloupe', code: 'GP' },
                { name: 'Guam', code: 'GU' },
                { name: 'Guatemala', code: 'GT' },
                { name: 'Guernsey', code: 'GG' },
                { name: 'Guinea', code: 'GN' },
                { name: 'Guinea-Bissau', code: 'GW' },
                { name: 'Guyana', code: 'GY' },
                { name: 'Haiti', code: 'HT' },
                { name: 'Heard Island and Mcdonald Islands', code: 'HM' },
                { name: 'Holy See (Vatican City State)', code: 'VA' },
                { name: 'Honduras', code: 'HN' },
                { name: 'Hong Kong', code: 'HK' },
                { name: 'Hungary', code: 'HU' },
                { name: 'Iceland', code: 'IS' },
                { name: 'India', code: 'IN' },
                { name: 'Indonesia', code: 'ID' },
                { name: 'Iran, Islamic Republic Of', code: 'IR' },
                { name: 'Iraq', code: 'IQ' },
                { name: 'Ireland', code: 'IE' },
                { name: 'Isle of Man', code: 'IM' },
                { name: 'Israel', code: 'IL' },
                { name: 'Italy', code: 'IT' },
                { name: 'Jamaica', code: 'JM' },
                { name: 'Japan', code: 'JP' },
                { name: 'Jersey', code: 'JE' },
                { name: 'Jordan', code: 'JO' },
                { name: 'Kazakhstan', code: 'KZ' },
                { name: 'Kenya', code: 'KE' },
                { name: 'Kiribati', code: 'KI' },
                { name: 'Korea, Democratic People\'S Republic of', code: 'KP' },
                { name: 'Korea, Republic of', code: 'KR' },
                { name: 'Kuwait', code: 'KW' },
                { name: 'Kyrgyzstan', code: 'KG' },
                { name: 'Lao People\'S Democratic Republic', code: 'LA' },
                { name: 'Latvia', code: 'LV' },
                { name: 'Lebanon', code: 'LB' },
                { name: 'Lesotho', code: 'LS' },
                { name: 'Liberia', code: 'LR' },
                { name: 'Libyan Arab Jamahiriya', code: 'LY' },
                { name: 'Liechtenstein', code: 'LI' },
                { name: 'Lithuania', code: 'LT' },
                { name: 'Luxembourg', code: 'LU' },
                { name: 'Macao', code: 'MO' },
                { name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK' },
                { name: 'Madagascar', code: 'MG' },
                { name: 'Malawi', code: 'MW' },
                { name: 'Malaysia', code: 'MY' },
                { name: 'Maldives', code: 'MV' },
                { name: 'Mali', code: 'ML' },
                { name: 'Malta', code: 'MT' },
                { name: 'Marshall Islands', code: 'MH' },
                { name: 'Martinique', code: 'MQ' },
                { name: 'Mauritania', code: 'MR' },
                { name: 'Mauritius', code: 'MU' },
                { name: 'Mayotte', code: 'YT' },
                { name: 'Mexico', code: 'MX' },
                { name: 'Micronesia, Federated States of', code: 'FM' },
                { name: 'Moldova, Republic of', code: 'MD' },
                { name: 'Monaco', code: 'MC' },
                { name: 'Mongolia', code: 'MN' },
                { name: 'Montserrat', code: 'MS' },
                { name: 'Morocco', code: 'MA' },
                { name: 'Mozambique', code: 'MZ' },
                { name: 'Myanmar', code: 'MM' },
                { name: 'Namibia', code: 'NA' },
                { name: 'Nauru', code: 'NR' },
                { name: 'Nepal', code: 'NP' },
                { name: 'Netherlands', code: 'NL' },
                { name: 'Netherlands Antilles', code: 'AN' },
                { name: 'New Caledonia', code: 'NC' },
                { name: 'New Zealand', code: 'NZ' },
                { name: 'Nicaragua', code: 'NI' },
                { name: 'Niger', code: 'NE' },
                { name: 'Nigeria', code: 'NG' },
                { name: 'Niue', code: 'NU' },
                { name: 'Norfolk Island', code: 'NF' },
                { name: 'Northern Mariana Islands', code: 'MP' },
                { name: 'Norway', code: 'NO' },
                { name: 'Oman', code: 'OM' },
                { name: 'Pakistan', code: 'PK' },
                { name: 'Palau', code: 'PW' },
                { name: 'Palestinian Territory, Occupied', code: 'PS' },
                { name: 'Panama', code: 'PA' },
                { name: 'Papua New Guinea', code: 'PG' },
                { name: 'Paraguay', code: 'PY' },
                { name: 'Peru', code: 'PE' },
                { name: 'Philippines', code: 'PH' },
                { name: 'Pitcairn', code: 'PN' },
                { name: 'Poland', code: 'PL' },
                { name: 'Portugal', code: 'PT' },
                { name: 'Puerto Rico', code: 'PR' },
                { name: 'Qatar', code: 'QA' },
                { name: 'Reunion', code: 'RE' },
                { name: 'Romania', code: 'RO' },
                { name: 'Russian Federation', code: 'RU' },
                { name: 'RWANDA', code: 'RW' },
                { name: 'Saint Helena', code: 'SH' },
                { name: 'Saint Kitts and Nevis', code: 'KN' },
                { name: 'Saint Lucia', code: 'LC' },
                { name: 'Saint Pierre and Miquelon', code: 'PM' },
                { name: 'Saint Vincent and the Grenadines', code: 'VC' },
                { name: 'Samoa', code: 'WS' },
                { name: 'San Marino', code: 'SM' },
                { name: 'Sao Tome and Principe', code: 'ST' },
                { name: 'Saudi Arabia', code: 'SA' },
                { name: 'Senegal', code: 'SN' },
                { name: 'Serbia and Montenegro', code: 'CS' },
                { name: 'Seychelles', code: 'SC' },
                { name: 'Sierra Leone', code: 'SL' },
                { name: 'Singapore', code: 'SG' },
                { name: 'Slovakia', code: 'SK' },
                { name: 'Slovenia', code: 'SI' },
                { name: 'Solomon Islands', code: 'SB' },
                { name: 'Somalia', code: 'SO' },
                { name: 'South Africa', code: 'ZA' },
                { name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
                { name: 'Spain', code: 'ES' },
                { name: 'Sri Lanka', code: 'LK' },
                { name: 'Sudan', code: 'SD' },
                { name: 'Suriname', code: 'SR' },
                { name: 'Svalbard and Jan Mayen', code: 'SJ' },
                { name: 'Swaziland', code: 'SZ' },
                { name: 'Sweden', code: 'SE' },
                { name: 'Switzerland', code: 'CH' },
                { name: 'Syrian Arab Republic', code: 'SY' },
                { name: 'Taiwan, Province of China', code: 'TW' },
                { name: 'Tajikistan', code: 'TJ' },
                { name: 'Tanzania, United Republic of', code: 'TZ' },
                { name: 'Thailand', code: 'TH' },
                { name: 'Timor-Leste', code: 'TL' },
                { name: 'Togo', code: 'TG' },
                { name: 'Tokelau', code: 'TK' },
                { name: 'Tonga', code: 'TO' },
                { name: 'Trinidad and Tobago', code: 'TT' },
                { name: 'Tunisia', code: 'TN' },
                { name: 'Turkey', code: 'TR' },
                { name: 'Turkmenistan', code: 'TM' },
                { name: 'Turks and Caicos Islands', code: 'TC' },
                { name: 'Tuvalu', code: 'TV' },
                { name: 'Uganda', code: 'UG' },
                { name: 'Ukraine', code: 'UA' },
                { name: 'United Arab Emirates', code: 'AE' },
                { name: 'United Kingdom', code: 'GB' },
                { name: 'United States', code: 'US' },
                { name: 'United States Minor Outlying Islands', code: 'UM' },
                { name: 'Uruguay', code: 'UY' },
                { name: 'Uzbekistan', code: 'UZ' },
                { name: 'Vanuatu', code: 'VU' },
                { name: 'Venezuela', code: 'VE' },
                { name: 'Viet Nam', code: 'VN' },
                { name: 'Virgin Islands, British', code: 'VG' },
                { name: 'Virgin Islands, U.S.', code: 'VI' },
                { name: 'Wallis and Futuna', code: 'WF' },
                { name: 'Western Sahara', code: 'EH' },
                { name: 'Yemen', code: 'YE' },
                { name: 'Zambia', code: 'ZM' },
                { name: 'Zimbabwe', code: 'ZW' }
            ],
            gender: [
                { name: 'Male' },
                { name: 'Female' },
                { name: 'Rather not say' },
            ],
        }
    },
    computed: {
        isFormValid() {
            return this.quizes.map((quiz) => {
                return quiz.questions?.map((question) => {
                    return !!question.answer;
                }).every((answer) => {
                    return answer === true
                })
            }).every((answer) => {
                return answer === true
            })
        }
    },
    methods: {
        onSubmit() {
            if (this.input.email == 0) {
                error => {
                    console.log(error);
                    this.isLoading = false
                }
            } else {
                this.isLoading = true
                this.dialog = false
                // console.log(this.input)
                axios.post('https://api.oneworldbali.com/free-dosha-quiz/freesave', {
                    // axios.post('http://localhost:3344/free-dosha-quiz/freesave', {
                    salutation: this.input.salutation,
                    mobile: this.input.mobile,
                    Experienced_PK_before: this.input.Experienced_PK_before,
                    first_name: this.input.first_name,
                    last_name: this.input.last_name,
                    email: this.input.email,
                    gender: this.input.gender,
                    country: this.input.country,
                    quizes: this.quizes,
                    familiar: this.input.familiar,
                    been_guest: this.input.been_guest,
                    update_news: this.input.update_news,
                }).then(response => {
                    // axios.get('https://api.oneworldbali.com/panchakarma/price-list-standart').then(response => {
                    // console.log(response.data)
                    this.$router.push(`/free-result-quiz/${response.data.insertId}/#result`)
                        .then(() => {
                            window.scrollTo(0, 0);
                        })
                    this.isLoading = false
                }).catch(error => {
                    console.log(error);
                    this.isLoading = false
                })
            }
        }
    }
}
</script>

<style scoped>
h1 {
    font-size: 40pt;
    font-weight: 700;
}
</style>