import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import 'material-design-icons-iconfont/dist/material-design-icons.css' // Ensure you are using css-loader

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
      iconfont: 'mdi', // default - only for display purposes
    },
    theme: {
        options: {
          customProperties: true,
        },
      themes: {
        light: {
          primary: '#677868',
          secondary: '#223f4a',
          accent: '#405861',
          error: '#FF5252',
          info: '#2196F3',
          success: '#4CAF50',
          warning: '#FFC107',
          footer: '#677868'
        },
      },
    },
  });
